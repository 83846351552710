import React, { useEffect, useState } from 'react';
import './Security.scss';
import { MuiTelInput } from 'mui-tel-input';
import CityModal from '../../components/CityModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetState,
  updateAdminEmail,
  updateAdminInfo,
  updateAdminPassword,
} from '../../redux/userSlice';
import { Alert } from '@mui/material';
import { handleAutoLogout, isEmailValid } from '../../utils/callBack';
const Security = () => {
  const { currentUser, error, isUpdated } = useSelector((state) => state.user);
  const [phone, setPhone] = useState(currentUser.telephone);
  const [email, setEmail] = useState(currentUser.email);
  const [emailError, setEmailError] = useState(null);
  const [openEmailEditModal, setOpenEmailEditModal] = useState(false);

  const [passwordData, setPasswordData] = useState({
    password: '',
    newPassword: '',
    confirm_new_password: '',
    passwordMatch: true, // Initially assume passwords match
  });
  const dispatch = useDispatch();

  const handleSubmitPassword = (event) => {
    event.preventDefault();
    if (!passwordData.passwordMatch) {
      return;
    }
    dispatch(
      updateAdminPassword({
        password: passwordData?.password,
        newPassword: passwordData?.newPassword,
        adminId: currentUser?._id,
      })
    );
    setPasswordData({
      password: '',
      newPassword: '',
      confirm_new_password: '',
      passwordMatch: true, // Initially assume passwords match
    });
    if (!error.status) {
      setTimeout(() => {
        handleAutoLogout(dispatch);
      }, [3000]);
    }
  };

  // makes the alert disapperas after 3 seconds in either cases error or success
  useEffect(() => {
    if (error.status || isUpdated) {
      setTimeout(() => {
        dispatch(resetState());
      }, [5000]);
    }
  }, [error, isUpdated]);

  const handleChangePassword = (event) => {
    const { name, value } = event.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
      passwordMatch:
        name === 'confirm_new_password'
          ? value === passwordData.newPassword
          : passwordData.newPassword === passwordData.confirm_new_password,
    });
  };

  const updateEmail = (e) => {
    e.preventDefault();
    if (!isEmailValid(email)) {
      setEmailError('Email is not valid');
      return;
    }
    dispatch(updateAdminEmail({ adminId: currentUser._id, email }));
    setOpenEmailEditModal(false);
  };

  const handleSubmitPhone = (event) => {
    event.preventDefault();
    dispatch(
      updateAdminInfo({ adminId: currentUser._id, data: { telephone: phone } })
    );
  };

  return (
    <>
      <CityModal
        open={openEmailEditModal}
        name="Edit email address"
        handleClose={() => setOpenEmailEditModal(false)}
        content={
          <form className="content-2">
            <p>
              Upon adding your new email, an activation email will be sent to
              your current email address. Simply open the email, and your new
              email will be activated automatically.
            </p>
            <input
              style={{ border: emailError ? '1px solid red' : '' }}
              placeholder="Email address"
              onChange={(e) => {
                setEmail(e.target.value);
                if (emailError && isEmailValid(email)) setEmailError(null);
              }}
            />
            <p style={{ color: 'red' }}>{emailError}</p>
            <button
              type="submit"
              className="green-button"
              onClick={updateEmail}
            >
              Save changes
            </button>
            <button
              onClick={() => setOpenEmailEditModal(false)}
              className="white-button"
            >
              Cancel
            </button>
          </form>
        }
      />
      {(error.status || isUpdated) && (
        <Alert severity={error.status ? 'error' : 'success'}>
          {error.status ? error.message : 'Changes saved successfully!'}
        </Alert>
      )}
      <div className="security-wrapper">
        <div className="password-security">
          <h1>Password</h1>
          <form onSubmit={handleSubmitPassword}>
            <input
              value={passwordData?.password}
              name="password"
              onChange={handleChangePassword}
              required
              type="password"
              placeholder="Current Password"
            />
            <div className="input-row">
              <input
                value={passwordData?.newPassword}
                name="newPassword"
                onChange={handleChangePassword}
                required
                type="password"
                placeholder="New Password"
              />
              <input
                value={passwordData?.confirm_new_password}
                name="confirm_new_password"
                onChange={handleChangePassword}
                required
                type="password"
                placeholder="Confirm New Password"
                pattern={passwordData?.newPassword} // Match the pattern of new password
                title="Passwords must match"
              />
            </div>
            <button className="green-button">Save Changes</button>
          </form>
        </div>
        <div className="password-security">
          <div className="top-part-security">
            <div className="left-side-security">
              <h1>Email</h1>
              <p>{currentUser.isEmailVerified ? 'Verified' : 'Pending'}</p>
            </div>
            <button
              className="light-green-button"
              onClick={() => setOpenEmailEditModal(true)}
            >
              Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <g clipPath="url(#clip0_3899_51850)">
                  <path
                    d="M7.33398 3.59766H2.66732C2.3137 3.59766 1.97456 3.73813 1.72451 3.98818C1.47446 4.23823 1.33398 4.57737 1.33398 4.93099V14.2643C1.33398 14.6179 1.47446 14.9571 1.72451 15.2071C1.97456 15.4572 2.3137 15.5977 2.66732 15.5977H12.0007C12.3543 15.5977 12.6934 15.4572 12.9435 15.2071C13.1935 14.9571 13.334 14.6179 13.334 14.2643V9.59766"
                    stroke="#219D50"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.334 2.59781C12.5992 2.33259 12.9589 2.18359 13.334 2.18359C13.7091 2.18359 14.0688 2.33259 14.334 2.59781C14.5992 2.86302 14.7482 3.22273 14.7482 3.59781C14.7482 3.97288 14.5992 4.33259 14.334 4.59781L8.00065 10.9311L5.33398 11.5978L6.00065 8.93114L12.334 2.59781Z"
                    stroke="#219D50"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3899_51850">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.929688)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <input
            value={currentUser.email}
            name="email"
            disabled
            placeholder="Email Address"
          />
        </div>
        <div className="password-security">
          <form onSubmit={handleSubmitPhone}>
            <div className="top-part-security">
              <div className="left-side-security">
                <h1>Phone number</h1>
              </div>
            </div>
            <MuiTelInput
              className="no-border-input"
              InputLabelProps={{
                sx: {
                  color: '#616161',
                  fontWeight: 400,
                  fontSize: '16px',
                },
              }}
              InputProps={{
                sx: {
                  '&:hover fieldset': {
                    border: '1px solid #122146 !important',
                  },
                  '&:focus-within fieldset, &:focus-visible fieldset': {
                    border: '1px solid #122146 !important',
                    boxShadow:
                      '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                  },
                },
              }}
              fullWidth
              defaultCountry="CA"
              onChange={(value) => setPhone(value)}
              value={phone}
            />
            <button className="green-button" onClick={handleSubmitPhone}>
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Security;

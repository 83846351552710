import React from 'react';
import {Container} from "@mui/material";
import DoctorInfo from "../DoctorInfo";
import ListNavigation from "../ListNavigation";
import {Outlet} from "react-router-dom";

const DashboardLayout = ({children}) => {
    return (
        <div className="prescription-wrapper">
            <Container sx={{
                maxWidth: "1352px !important",
                padding: "0px !important",
                paddingY: "40px !important",
                display: "flex",
                gap: "24px"
            }}>
                <div className='left-side'>
                    <DoctorInfo/>
                    <ListNavigation/>
                </div>
                <div className='right-side'>
                    {children}
                    <Outlet/>
                </div>
            </Container>
        </div>
    );
};

export default DashboardLayout;
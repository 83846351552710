import React, { useState, useEffect } from 'react';
import './PrescriptionItems.scss';
import { Divider, IconButton, TextField, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
  addBasketItem,
  addTax,
  deleteBasketItem,
  updateBasketItem,
  updatePrescriptionById,
} from '../redux/prescriptionSlice';
import { useParams } from 'react-router-dom';

const PrescriptionItems = ({ prescriptionDetails, setTax, tax }) => {
  const { id } = useParams();
  const [refilldDate, setRefillDate] = useState('');
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:1200px)');
  const [prescriptionNumber, setPrescriptionNumber] = useState();
  const [prescriptionItems, setPrescriptionItems] = useState([]);
  const [isItemFormValid, setIsItemFormValid] = useState(true);
  const [isEditItemFormValid, setIsEditItemFormValid] = useState(true);
  const [subTotal, setSubtotal] = useState(0);
  const [editItemData, setEditItemData] = useState({});
  const [item, setItem] = useState({
    medication_name: '',
    qty: 1,
    price: '',
    dosage: ' ',
  });

  useEffect(() => {
    if (prescriptionDetails) {
      setPrescriptionNumber(prescriptionDetails?.number);
      setPrescriptionItems(prescriptionDetails?.basket_items);
    }
  }, [prescriptionDetails]);

  const handleAddBasketItem = () => {
    if (!item.medication_name || !item.price || item.price <= 0) {
      setIsItemFormValid(false);
    } else {
      setIsItemFormValid(true);
      dispatch(addBasketItem({ id, basketItem: item }));
    }
  };
  const handleSaveEditItem = () => {
    if (
      !editItemData.medication_name ||
      !editItemData.qty ||
      !editItemData.price ||
      !editItemData.dosage
    ) {
      setIsEditItemFormValid(false);
    } else {
      setIsEditItemFormValid(true);
      dispatch(
        updateBasketItem({ prescriptionId: id, basketItem: editItemData })
      );
      setEditItemData({});
    }
  };

  const handleItemInputChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const deleteItem = (basketItemId) => {
    dispatch(deleteBasketItem({ prescriptionId: id, basketItemId }));
  };

  const handleChange = (index, newNote) => {
    const updatedPrescriptionItems = [...prescriptionItems];
    updatedPrescriptionItems[index].note = newNote;
    setPrescriptionItems(updatedPrescriptionItems);
  };

  const calculatePrices = () => {
    const subTotal = prescriptionItems?.reduce((subTotal, medication) => {
      const medicationSubTotalPrice = +medication.qty * +medication.price;
      return +subTotal + +medicationSubTotalPrice;
    }, 0);

    setSubtotal(+subTotal);
  };

  useEffect(() => {
    calculatePrices();
  }, [prescriptionItems]);

  const updatePrescriptionNumber = () => {
    dispatch(
      updatePrescriptionById({ id, newData: { number: prescriptionNumber } })
    );
  };
  // const handleUpdatePrescriptionRefillDate = () => {
  //   dispatch(
  //     updatePrescriptionById({ id, newData: { refill_date: refilldDate } })
  //   );
  // };
  return (
    <div className="prescription-items-wrapper">
      <h1>Prescription Items</h1>
      {!(
        prescriptionDetails?.status === 'Paid' ||
        prescriptionDetails?.order?._id
      ) && (
        <div className="prescription-number-wrapper">
          <h1>Prescription number (PMS)</h1>
          <div className="input-box">
            <input
              placeholder="Prescription Number"
              value={prescriptionNumber}
              onChange={(e) => setPrescriptionNumber(e.target.value)}
            />
            <button onClick={updatePrescriptionNumber}>Change</button>
          </div>
        </div>
      )}
      {!matches ? (
        <table>
          <thead>
            <th>Name</th>
            {prescriptionDetails?.status === 'Processing' && <th>Quantity</th>}
            <th style={{ width: '10%' }}>
              {prescriptionDetails?.status === 'Processing'
                ? 'Price'
                : 'Final Price'}
            </th>
            <th>Pharmacy Notes</th>
            {prescriptionDetails?.status === 'Processing' &&
              !prescriptionDetails?.order?._id && <th></th>}
          </thead>
          <tbody>
            {prescriptionItems?.length > 0 ? (
              prescriptionItems?.map((presc, index) => (
                <tr key={index}>
                  <td>
                    {editItemData?._id === presc?._id ? (
                      <input
                        style={{
                          border:
                            !isEditItemFormValid &&
                            !editItemData.medication_name.length
                              ? '1px solid red'
                              : '1px solid #DEDEDE',
                          padding: '5px',
                          maxWidth: '100%',
                        }}
                        name="medication_name"
                        value={editItemData?.medication_name}
                        placeholder="Medication Name"
                        type="text"
                        required
                        onChange={(e) =>
                          setEditItemData({
                            ...editItemData,
                            medication_name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      index + 1 + '. ' + presc?.medication_name
                    )}
                  </td>
                  <td>
                    {' '}
                    {editItemData?._id === presc?._id ? (
                      <input
                        style={{
                          border:
                            !isEditItemFormValid && !editItemData.qty.length
                              ? '1px solid red'
                              : '1px solid #DEDEDE',
                          padding: '5px',
                          maxWidth: '100%',
                        }}
                        name="qty"
                        value={editItemData?.qty}
                        placeholder="Quantity"
                        type="number"
                        required
                        onChange={(e) =>
                          setEditItemData({
                            ...editItemData,
                            qty: e.target.value,
                          })
                        }
                      />
                    ) : (
                      presc.qty ?? 'NA'
                    )}
                  </td>
                  <td>
                    {editItemData?._id === presc?._id ? (
                      <input
                        style={{
                          border:
                            !isEditItemFormValid && !editItemData.price.length
                              ? '1px solid red'
                              : '1px solid #DEDEDE',
                          padding: '5px',
                          maxWidth: '100%',
                        }}
                        name="price"
                        value={editItemData?.price}
                        placeholder="Price of Medicine"
                        type="number"
                        required
                        onChange={(e) =>
                          setEditItemData({
                            ...editItemData,
                            price: e.target.value,
                          })
                        }
                      />
                    ) : (
                      '$' + presc?.price
                    )}
                  </td>
                  <td>
                    {editItemData?._id === presc?._id ? (
                      <input
                        style={{
                          border:
                            !isEditItemFormValid && !editItemData.dosage.length
                              ? '1px solid red'
                              : '1px solid #DEDEDE',
                          padding: '5px',
                          maxWidth: '100%',
                        }}
                        placeholder="Write Note of Medicine"
                        name="dosage"
                        value={editItemData?.dosage}
                        required
                        onChange={(e) =>
                          setEditItemData({
                            ...editItemData,
                            dosage: e.target.value,
                          })
                        }
                      />
                    ) : (
                      presc?.dosage
                    )}
                  </td>
                  {editItemData._id !== presc._id &&
                    !prescriptionDetails?.order?._id && (
                      <td style={{ width: '10%' }}>
                        {/* edit button */}
                        <IconButton onClick={() => setEditItemData(presc)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              d="M9.16797 3.90234H3.33464C2.89261 3.90234 2.46868 4.07794 2.15612 4.3905C1.84356 4.70306 1.66797 5.12698 1.66797 5.56901V17.2357C1.66797 17.6777 1.84356 18.1016 2.15612 18.4142C2.46868 18.7267 2.89261 18.9023 3.33464 18.9023H15.0013C15.4433 18.9023 15.8673 18.7267 16.1798 18.4142C16.4924 18.1016 16.668 17.6777 16.668 17.2357V11.4023"
                              stroke="#5C5F62"
                              strokeWidth="1.875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.418 2.65058C15.7495 2.31906 16.1991 2.13281 16.668 2.13281C17.1368 2.13281 17.5864 2.31906 17.918 2.65058C18.2495 2.9821 18.4357 3.43174 18.4357 3.90058C18.4357 4.36942 18.2495 4.81906 17.918 5.15058L10.0013 13.0672L6.66797 13.9006L7.5013 10.5672L15.418 2.65058Z"
                              stroke="#5C5F62"
                              strokeWidth="1.875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButton>
                        {/* delete button */}
                        <IconButton
                          onClick={() => {
                            deleteItem(presc._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              d="M2.5 5.56641H17.5"
                              stroke="#5C5F62"
                              strokeWidth="1.875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.8346 5.56641V17.2331C15.8346 18.0664 15.0013 18.8997 14.168 18.8997H5.83464C5.0013 18.8997 4.16797 18.0664 4.16797 17.2331V5.56641"
                              stroke="#5C5F62"
                              strokeWidth="1.875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.66797 5.5638V3.89714C6.66797 3.0638 7.5013 2.23047 8.33464 2.23047H11.668C12.5013 2.23047 13.3346 3.0638 13.3346 3.89714V5.5638"
                              stroke="#5C5F62"
                              strokeWidth="1.875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.33203 9.73047V14.7305"
                              stroke="#5C5F62"
                              strokeWidth="1.875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.668 9.73047V14.7305"
                              stroke="#5C5F62"
                              strokeWidth="1.875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButton>
                      </td>
                    )}
                  {editItemData._id === presc._id &&
                    !prescriptionDetails?.order?._id && (
                      <td style={{ width: '10%' }}>
                        {/* save edit button */}
                        <IconButton onClick={() => handleSaveEditItem()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M7.72656 14.9297L4.35156 11.5547L3.58594 12.3203L7.72656 16.4609L16.0859 8.10156L15.3203 7.33594L7.72656 14.9297Z"
                              fill="#5C5F62"
                              stroke="#5C5F62"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButton>

                        {/* cancel edit button */}
                        <IconButton onClick={() => setEditItemData({})}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.5 5.5L14.5 14.5M14.5 5.5L5.5 14.5"
                              fill="#5C5F62"
                              stroke="#5C5F62"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButton>
                      </td>
                    )}
                </tr>
              ))
            ) : (
              <td
                colSpan="3"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <div className="empty-table">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="65"
                    height="66"
                    viewBox="0 0 65 66"
                    fill="none"
                  >
                    <path
                      d="M28.4232 17.6827L33.8399 12.2661C36.3625 9.79384 39.7589 8.41707 43.291 8.4349C46.8231 8.45274 50.2054 9.86376 52.7029 12.3613C55.2005 14.8589 56.6115 18.2412 56.6294 21.7733C56.6472 25.3053 55.2704 28.7017 52.7982 31.2244L47.3815 36.6411M41.9649 42.0577L31.1315 52.8911C28.6089 55.3633 25.2125 56.7401 21.6804 56.7222C18.1484 56.7044 14.7661 55.2934 12.2685 52.7958C9.7709 50.2982 8.35989 46.9159 8.34205 43.3839C8.32421 39.8518 9.70099 36.4554 12.1732 33.9327L23.0065 23.0994M23.0208 23.0872L41.9792 42.0456M8.125 8.19141L56.875 56.9414"
                      stroke="#8F91A1"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h1 style={{ textAlign: 'center' }}>
                    No medication added yet.
                  </h1>
                  {/* <p>To view the medications associated with a specific prescription number, please
                                        include
                                        the prescription number.</p> */}
                </div>
              </td>
            )}
          </tbody>
        </table>
      ) : (
        <div className="mobile-table">
          {prescriptionItems?.map((prescription, index) => (
            <div className="inside-table">
              <div className="top-part">
                <h1>{prescription?.medication_name}</h1>
                <p>Price</p>
                <p className="price">${prescription?.price}</p>
              </div>
              {prescriptionDetails?.status !== 'Paid' ||
              prescriptionDetails?.order?._id ? (
                <input
                  disabled={true}
                  value={prescription.dosage}
                  onChange={(e) => handleChange(index, e.target.value)}
                  name={prescription.name}
                  placeholder="Write note of medicine"
                />
              ) : (
                <p>{prescription.note}</p>
              )}
            </div>
          ))}
        </div>
      )}

      {!prescriptionDetails?.order?._id && (
        <div className="buttons-box">
          {/* <select required className='input-select' style={{ width: "50%" }}>
                        <option value="">Search a Medicine</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select> */}
          <input
            style={{
              width: '50%',
              border:
                !isItemFormValid && !item.medication_name.length
                  ? '1px solid red'
                  : '1px solid #DEDEDE',
            }}
            name="medication_name"
            placeholder="Medication Name"
            type="text"
            required
            onChange={handleItemInputChange}
          />
          {/* <input
            style={{
              width: '30%',
              border:
                !isItemFormValid && !item.qty.length
                  ? '1px solid red'
                  : '1px solid #DEDEDE',
            }}
            name="qty"
            placeholder="Quantity"
            type="number"
            required
            onChange={handleItemInputChange}
          /> */}
          <input
            style={{
              width: '40%',
              border:
                !isItemFormValid && !item.price.length
                  ? '1px solid red'
                  : '1px solid #DEDEDE',
            }}
            name="price"
            placeholder="Price of Medicine"
            type="number"
            required
            onChange={handleItemInputChange}
          />
          <input
            style={{
              border:
                !isItemFormValid && !item.dosage.length
                  ? '1px solid red'
                  : '1px solid #DEDEDE',
            }}
            placeholder="Write Note of Medicine"
            name="dosage"
            required
            onChange={handleItemInputChange}
          />
          <button
            style={{ width: '20%', height: '50px' }}
            className="white-button"
            onClick={handleAddBasketItem}
          >
            + Add
          </button>
        </div>
      )}
      <div className="patient-order-summary">
        <h1>Patient Order Summary</h1>
        <div className="total-wrapper">
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={dayjs(prescriptionDetails?.refill_date || refilldDate)}
              label="Add refill date"
              onChange={(value) => setRefillDate(new Date(value['$d']))}
              onClose={handleUpdatePrescriptionRefillDate}
            />
          </LocalizationProvider> */}

          <TextField
            sx={{ width: { xs: '100%', md: 'auto' } }}
            id="outlined-textarea"
            value={tax}
            label="Enter the overall taxes"
            placeholder="Example: $5.2"
            type="number"
            disabled={prescriptionDetails?.order?._id}
            onChange={(e) => {
              setTax(e.target.value);
            }}
          />
          <Divider
            sx={{ display: { xs: 'none', md: 'flex' } }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <div className="sub-total">
            <p>Subtotal</p>
            <p className="price">{subTotal.toFixed(2)}</p>
          </div>
          <Divider
            sx={{ display: { xs: 'none', md: 'flex' } }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <div className="sub-total">
            <p>Total Price</p>
            <p className="price">
              {(
                subTotal +
                  Number(tax) +
                  prescriptionDetails?.order?.delivery_method?.cost || 0
              ).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionItems;

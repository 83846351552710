import React from 'react';
import './AddMember.scss'
import {useNavigate} from "react-router-dom";
import useForm from "../../hooks/useForm";

const AddMember = () => {
    const navigate = useNavigate();
    const {data, handleChange} = useForm();
    const handleSubmit = (event) => {
        event.preventDefault();
        // Add backend here
    }

    return (
        <div className='add-member-wrapper'>
            <button className='back-button' onClick={() => navigate("/settings/member-settings")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M12.6673 8.92969H3.33398" stroke="#219D50" strokeWidth="1.8" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M8.00065 13.5951L3.33398 8.92839L8.00065 4.26172" stroke="#219D50" strokeWidth="1.8"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Back
            </button>
            <h1>Add Member</h1>
            <form onSubmit={handleSubmit}>
                <div className='input-row'>
                    <input value={data?.first_name} name="first_name" onChange={handleChange} required
                           placeholder='First name'/>
                    <input value={data?.last_name} name="last_name" onChange={handleChange} required
                           placeholder='Last name'/>
                    <input value={data?.email} name="email" onChange={handleChange} required placeholder='Email'/>
                </div>
                <div className='input-row'>
                    <select onChange={handleChange} name="status" value={data?.status} required className='input-select'>
                        <option value="">Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>

                    </select>
                    <select onChange={handleChange} name="role" value={data?.role} required className='input-select'>
                        <option value="">Role</option>
                        <option value="Admin">Admin</option>
                        <option value="Manager">Manager</option>
                        <option value="Pharmacist">Pharmacist</option>
                        <option value="Delivery Man">Delivery Man</option>
                        <option value="Salesman">Salesman</option>
                    </select>
                </div>
                <button className='green-button'>
                    Add Member
                </button>
            </form>
        </div>
    );
};

export default AddMember;
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Alert } from '@mui/material';
import '../Prescription/Prescription.scss';
import PrescriptionName from '../../components/PrescriptionName';
import PrescriptionItems from '../../components/PrescriptionItems';
import ConfirmArchiveCancelButtons from '../../components/ConfirmArchiveCancelButtons';
import { useSelector, useDispatch } from 'react-redux';
import { getPrescriptionById } from '../../redux/prescriptionSlice';
import { useParams } from 'react-router-dom';

const PrescriptionDetails = () => {
  const dispatch = useDispatch();
  const {
    prescriptionDetails = {},
    isLoading,
    error,
    isUpdating,
  } = useSelector((state) => state.prescription);

  const { id } = useParams();
  const [tax, setTax] = useState(prescriptionDetails?.order?.tax_amount || 0);
  useEffect(() => {
    dispatch(getPrescriptionById(id));
  }, [id]);

  return (
    <>
      {error.status && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            bottom: '16px',
            right: '16px',
            zIndex: '50',
          }}
        >
          {error?.message}
        </Alert>
      )}
      {isLoading && prescriptionDetails?.status ? (
        <Box
          style={{
            height: '60vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress color="success" />
          <span>Loading Data...</span>
        </Box>
      ) : (
        <>
          {isUpdating && (
            <Box
              sx={{
                backgroundColor: '#ffff',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                opacity: 0.6,
                zIndex: '100',
              }}
            ></Box>
          )}
          <PrescriptionName prescriptionDetails={prescriptionDetails} />
          <PrescriptionItems
            prescriptionDetails={prescriptionDetails}
            setTax={setTax}
            tax={tax}
          />
          {!prescriptionDetails?.order?._id && (
            <ConfirmArchiveCancelButtons tax={tax} />
          )}
        </>
      )}
    </>
  );
};

export default PrescriptionDetails;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { socket } from '../socket';
import { handleAutoLogout } from '../utils/callBack';

export const getChatByPrescId = createAsyncThunk(
  'chat/getChatByPrescId',
  async ( prescriptionId , { dispatch }) => {
    try {
      const response = await userRequest.get(
        `/admin/chat/${prescriptionId}`
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new address');
      }
    }
  }
);
export const sendNewMessage = createAsyncThunk(
  'chat/sendNewMessage',
  async ({ userId, chatId, content, recieverId }, { dispatch }) => {
    try {
      const response = await userRequest.post(
        `/admin/${userId}/sendMessage`,
        {
          chat_id: chatId,
          content: content,
        }
      );
      const newMessage = response.data;
      // handles live query to admin
      socket.emit('send message', {
        ...newMessage,
        recieverId: recieverId,
      });
      return newMessage;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new address');
      }
    }
  }
);

const ChatSlice = createSlice({
  name: 'chat',
  initialState: {
    conversation: null,
    isLoading: false,
    error: {
      status: false,
      message: null,
    },
  },
  reducers: {
    updateMessage: (state, action) => {
      const newMessage = action.payload;
      const oldMessages = state.conversation.messages;
      const isExists = oldMessages.find((mssg) => mssg._id === newMessage._id);
      if (!isExists) {
        const messages = [...state.conversation?.messages, newMessage];
        state.conversation = { ...state.conversation, messages };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatByPrescId.pending, (state) => {
        state.isLoading = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getChatByPrescId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conversation = action.payload;
      })
      .addCase(getChatByPrescId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed Loading chat',
        };
      })
      .addCase(sendNewMessage.pending, (state) => {
        state.isLoading = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(sendNewMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        const newMessage = action.payload;
        const messages = [...state.conversation?.messages, newMessage];
        state.conversation = { ...state.conversation, messages };
      })
      .addCase(sendNewMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed sending message',
        };
      });
  },
});
export const { updateMessage } = ChatSlice.actions;
export const ChatReducer = ChatSlice.reducer;

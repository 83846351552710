import React from 'react';
import './SendPrescriptionModal.scss'
import {IconButton, Modal} from "@mui/material";
import './ConfirmPrescriptionModal.scss'

const ConfirmPrescriptionModal = ({open, handleClose}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal'>
                <div className='top-part'>
                    <h1>{""}</h1>
                    <IconButton onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                            <path d="M27.9961 13L11.9961 29.0005" stroke="#979797" strokeWidth="1.28573"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.9961 13L27.9961 29.0005" stroke="#979797" strokeWidth="1.28573"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </IconButton>
                </div>
                <div className='confirm-message'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="79" viewBox="0 0 78 79" fill="none">
                        <path
                            d="M39 7.8125C21.4957 7.8125 7.3125 21.9957 7.3125 39.5C7.3125 57.0043 21.4957 71.1875 39 71.1875C56.5043 71.1875 70.6875 57.0043 70.6875 39.5C70.6875 21.9957 56.5043 7.8125 39 7.8125ZM34.1098 50.7277C33.7441 51.0934 33.2262 51.398 32.7691 51.398C32.3121 51.398 31.7941 51.0781 31.4133 50.7125L22.882 42.1813L25.5938 39.4695L32.7844 46.6602L51.7969 27.5105L54.4629 30.268L34.1098 50.7277Z"
                            fill="#219D50"/>
                    </svg>
                    <p>Notification Sent to Patient to Complete Payment</p>
                </div>
                <button onClick={handleClose}>back</button>
            </div>
        </Modal>
    );
};

export default ConfirmPrescriptionModal;
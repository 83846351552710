import React, { useEffect } from 'react';
import './PrescriptionStats.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getPrescriptionOrdersStats } from '../redux/prescriptionSlice';

const PrescriptionStats = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrescriptionOrdersStats());
  }, []);

  const stats = useSelector((state) => state.prescription.stats) || [];

  return (
    <div className="prescription-stats-wrapper">
      <div className="prescription-stats">
        {stats?.map((stat, index) => (
          <div key={index} className="stat">
            <div className="inside-stat">
              <h1>{stat?.period}</h1>
              <div className="stat-numbers">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 9H21V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V9Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 9.00005L5.45 4.10005C5.61696 3.7687 5.87281 3.49034 6.18893 3.29607C6.50504 3.10181 6.86897 2.99932 7.24 3.00005H16.76C17.1327 2.99745 17.4988 3.09907 17.8168 3.29344C18.1349 3.4878 18.3923 3.76718 18.56 4.10005L21 9.00005"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 3V9"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="numbers">
                  <h1>{stat?.numberOfOrders}</h1>
                  <p>Orders</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrescriptionStats;

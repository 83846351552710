import React from 'react';
import { Divider } from '@mui/material';

const PatientInformation = ({ prescription }) => {
  const shippingAddress = prescription?.order?.shipping_address;
  const nationalIdDocument = prescription?.documents?.find(
    (doc) => doc.type === 'nationalId'
  );
  const insuranceDocument = prescription?.documents?.find(
    (doc) => doc.type === 'insurance' && doc?.isDefault
  );
  return (
    <div className="paid-information-wrapper">
      <div className="patient-information-paid">
        <div className="patient-information">
          <div className="left-side-patient">
            <h1>Patient Information</h1>
            <td>
              {prescription?.patient_fname || prescription?.patient_lname
                ? prescription?.patient_fname +
                  ' ' +
                  prescription?.patient_lname
                : 'NA'}
            </td>

            <p>{prescription?.customerDetails?.dob || 'NA'}</p>
          </div>
          <div className="right-side-patient">
            <p>
              {prescription?.customerDetails?.email}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <g opacity="0.2">
                  <path
                    d="M13.834 2.66602H3.16732C2.43094 2.66602 1.83398 3.26297 1.83398 3.99935V11.9993C1.83398 12.7357 2.43094 13.3327 3.16732 13.3327H13.834C14.5704 13.3327 15.1673 12.7357 15.1673 11.9993V3.99935C15.1673 3.26297 14.5704 2.66602 13.834 2.66602Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.1673 4.66602L9.18732 8.46602C8.9815 8.59497 8.74353 8.66336 8.50065 8.66336C8.25777 8.66336 8.0198 8.59497 7.81398 8.46602L1.83398 4.66602"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </p>
            <p>
              {prescription?.phone ||
                prescription?.customerDetails?.telephone ||
                'NA'}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <g opacity="0.2">
                  <path
                    d="M15.1669 11.2807V13.2807C15.1677 13.4664 15.1297 13.6502 15.0553 13.8203C14.9809 13.9904 14.8718 14.1431 14.735 14.2686C14.5982 14.3941 14.4367 14.4897 14.2608 14.5492C14.0849 14.6087 13.8985 14.6308 13.7136 14.614C11.6622 14.3911 9.69161 13.6901 7.96028 12.5674C6.3495 11.5438 4.98384 10.1782 3.96028 8.56738C2.8336 6.82819 2.13244 4.84805 1.91361 2.78738C1.89695 2.60303 1.91886 2.41722 1.97795 2.2418C2.03703 2.06637 2.13199 1.90518 2.25679 1.76846C2.38159 1.63175 2.53348 1.52252 2.70281 1.44773C2.87213 1.37294 3.05517 1.33422 3.24028 1.33405H5.24028C5.56382 1.33086 5.87748 1.44543 6.12279 1.6564C6.3681 1.86737 6.52833 2.16035 6.57361 2.48072C6.65803 3.12076 6.81458 3.7492 7.04028 4.35405C7.12998 4.59266 7.14939 4.85199 7.09622 5.1013C7.04305 5.35061 6.91952 5.57946 6.74028 5.76072L5.89361 6.60738C6.84265 8.27641 8.22458 9.65835 9.89361 10.6074L10.7403 9.76072C10.9215 9.58147 11.1504 9.45795 11.3997 9.40478C11.649 9.35161 11.9083 9.37102 12.1469 9.46072C12.7518 9.68642 13.3802 9.84297 14.0203 9.92738C14.3441 9.97307 14.6399 10.1362 14.8513 10.3857C15.0627 10.6352 15.1751 10.9538 15.1669 11.2807Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </p>
          </div>
        </div>
        {shippingAddress && (
          <>
            <Divider sx={{ width: '100%' }} />
            <div className="left-side-patient">
              <h1>Shipping Address</h1>
              <p>
                {[
                  shippingAddress?.street[0],
                  shippingAddress?.city,
                  shippingAddress?.region?.region,
                  shippingAddress?.postcode,
                  shippingAddress?.country_id,
                ].join(', ')}
              </p>
            </div>
          </>
        )}
        {prescription?.order?.delivery_method && (
          <>
            <Divider sx={{ width: '100%' }} />
            <div className="patient-information start">
              <div className="left-side-patient">
                <h1>Delivery Method</h1>
                <p>
                  {prescription?.order?.method === 'Deliver' && (
                    <img
                      src={prescription?.order?.delivery_method?.img}
                      alt="payment-method"
                    />
                  )}
                  {prescription?.order?.delivery_method?.name}
                </p>
              </div>
              <div className="left-side-patient">
                <h1>Order Method</h1>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M5.25 17C5.25 17.5304 5.46071 18.0391 5.83579 18.4142C6.21086 18.7893 6.71957 19 7.25 19C7.78043 19 8.28914 18.7893 8.66421 18.4142C9.03929 18.0391 9.25 17.5304 9.25 17M5.25 17C5.25 16.4696 5.46071 15.9609 5.83579 15.5858C6.21086 15.2107 6.71957 15 7.25 15C7.78043 15 8.28914 15.2107 8.66421 15.5858C9.03929 15.9609 9.25 16.4696 9.25 17M5.25 17H3.25V13M9.25 17H15.25M15.25 17C15.25 17.5304 15.4607 18.0391 15.8358 18.4142C16.2109 18.7893 16.7196 19 17.25 19C17.7804 19 18.2891 18.7893 18.6642 18.4142C19.0393 18.0391 19.25 17.5304 19.25 17M15.25 17C15.25 16.4696 15.4607 15.9609 15.8358 15.5858C16.2109 15.2107 16.7196 15 17.25 15C17.7804 15 18.2891 15.2107 18.6642 15.5858C19.0393 15.9609 19.25 16.4696 19.25 17M19.25 17H21.25V11M2.25 5H13.25V17M21.25 11H13.25M21.25 11L18.25 6H13.25M3.25 9H7.25"
                      stroke="#080E26"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {prescription?.order?.method || 'N/A'}
                </p>
              </div>
            </div>
          </>
        )}
        {(prescription?.order?.prefered_time ||
          prescription?.order?.prefered_date) && (
          <>
            <Divider sx={{ width: '100%' }} />
            <div className="patient-information start">
              <div className="left-side-patient">
                <h1>Preferred Date</h1>
                <p>{prescription?.order?.prefered_date}</p>
              </div>
              <div className="left-side-patient">
                <h1>Preferred Time</h1>
                <p>{prescription?.order?.prefered_time}</p>
              </div>
            </div>
          </>
        )}
      </div>
      <Divider width={'96%'} sx={{ alignSelf: 'center' }} />
      <div className="patient-photo-id">
        <div className="photo-id">
          <h1>Patient Photo ID</h1>
          <div className="photo">
            {nationalIdDocument ? (
              <div>
                <img
                  src={nationalIdDocument?.attatchement_front_url}
                  alt="person"
                />
                <img
                  src={nationalIdDocument?.attatchement_back_url}
                  alt="person"
                />
              </div>
            ) : (
              <div
                className="empty-photo"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="66"
                  height="66"
                  viewBox="0 0 66 66"
                  fill="none"
                >
                  <path
                    d="M22.9033 22.677C23.4245 24.3456 24.3408 25.8637 25.5742 27.1024C26.8077 28.341 28.322 29.2636 29.9883 29.7918M39.4864 28.3158C40.779 27.4039 41.8561 26.2201 42.6422 24.8474C43.4284 23.4748 43.9043 21.9466 44.0367 20.3703C44.1691 18.7941 43.9547 17.208 43.4086 15.7234C42.8625 14.2389 41.9979 12.8919 40.8756 11.7772C39.7532 10.6625 38.4004 9.80714 36.9122 9.27115C35.4239 8.73517 33.8364 8.5316 32.2611 8.67474C30.6857 8.81788 29.1609 9.30427 27.7936 10.0997C26.4264 10.8952 25.2499 11.9804 24.3469 13.2791M17 57.375V51.9583C17 49.0852 18.1414 46.3297 20.173 44.298C22.2047 42.2664 24.9602 41.125 27.8333 41.125H38.6667C39.7825 41.125 40.8604 41.2929 41.8706 41.6071M49.0017 48.6975C49.3267 49.7267 49.5 50.8235 49.5 51.9583V57.375M8.875 8.625L57.625 57.375"
                    stroke="#8F91A1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>No Photo ID found.</p>
              </div>
            )}
          </div>
        </div>
        {/* <Divider sx={{ width: '100%' }} /> */}
        <div className="photo-id">
          <h1>Insurance</h1>
          <div className="photo">
            {insuranceDocument ? (
              <div>
                <img
                  src={insuranceDocument?.attatchement_front_url}
                  alt="person"
                />
                <img
                  src={insuranceDocument?.attatchement_back_url}
                  alt="person"
                />
              </div>
            ) : (
              <div
                className="empty-photo"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="66"
                  height="65"
                  viewBox="0 0 66 65"
                  fill="none"
                >
                  <path
                    d="M48.1084 47.8481C43.9303 52.2285 38.6117 55.3549 32.7522 56.875C28.5359 55.7814 24.5825 53.8514 21.1268 51.1996C17.6711 48.5479 14.7838 45.2286 12.6363 41.4389C10.4888 37.6493 9.12506 33.4666 8.62603 29.1394C8.12699 24.8123 8.50288 20.429 9.73136 16.25C11.8818 16.3475 14.0187 16.2337 16.1149 15.9142M24.5866 13.4062C27.558 12.0654 30.3107 10.2851 32.7522 8.125C39.0784 13.722 47.3351 16.6361 55.773 16.25C57.0126 20.4671 57.3838 24.8918 56.8643 29.2564C56.3448 33.6211 54.9453 37.835 52.7505 41.6433M8.375 8.125L57.125 56.875"
                    stroke="#8F91A1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>No Insurance Information found.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInformation;

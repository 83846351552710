import {useState} from 'react';

const useForm = () => {
    const [data, setData] = useState(null);

    const handleChange = (event) => {
        let name;
        let value;
        if (event.target) {
            name = event.target.name;
            value = event.target.value;
        } else {
            name = "phone";
            value = event;
        }

        setData({...data, [name]: value})
    }

    return {data, handleChange};
};

export default useForm;
import React from 'react';
import CityCheckbox from './CityCheckbox';
import { Divider, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/callBack';

const MobileCustomerHistoryTable = ({
  data,
  checkedPrescriptions,
  handleCheckPrescriptions,
}) => {
  const navigate = useNavigate();
  return (
    <div className="mobile-prescription-table-wrapper">
      {data.map((prescription, index) => {
        const isChecked = checkedPrescriptions.some(
          (checkedPresc) => checkedPresc?._id === prescription?._id
        );
        return (
          <>
            <div className="information-wrapper">
              <div className="name-part">
                <div className="top-part">
                  <div className="left-side">
                    <CityCheckbox
                      checked={isChecked}
                      onClick={(event) =>
                        handleCheckPrescriptions(event, prescription)
                      }
                    />
                    <h1>#{prescription?.number || 'N/A'}</h1>
                    {prescription.new && (
                      <div className="light-green-box">New</div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <div
                      className={
                        prescription.status === 'Processing'
                          ? 'grey-box2'
                          : prescription.status === 'Paid' ||
                            prescription.status === 'Delivered'
                          ? 'green-box2'
                          : prescription.status === 'Cancelled'
                          ? 'red-box'
                          : 'light-green-box'
                      }
                    >
                      {prescription.status}
                    </div>
                    {/* <IconButton sx={{ padding: '0px' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton> */}
                  </div>
                </div>
                <div className="prescription-information customer">
                  <p>
                    {prescription?.patient_fname || prescription?.patient_lname
                      ? prescription?.patient_fname +
                        ' ' +
                        prescription?.patient_lname
                      : 'NA'}
                  </p>
                  <Divider
                    sx={{ height: '10px' }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <p>{formatDate(prescription?.createdAt)}</p>
                </div>
                <button
                  className="green-button"
                  onClick={() =>
                    navigate(`/prescription/${prescription.number}`)
                  }
                >
                  Review
                </button>
              </div>
            </div>
            {index !== data.length - 1 && <Divider sx={{ width: '100%' }} />}
          </>
        );
      })}
    </div>
  );
};

export default MobileCustomerHistoryTable;

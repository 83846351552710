import React, { useEffect, useState } from 'react';
import './General.scss';
import { styled, Switch } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateAdminInfo, resetState } from '../../redux/userSlice';
import { Alert } from '@mui/material';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const General = () => {
  const { currentUser, isLoading, error, isUpdated } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const [data, setData] = useState(currentUser);

  // makes the alert disapperas after 5 seconds in either cases error or success
  useEffect(() => {
    if (isUpdated || error.status) {
      setTimeout(() => {
        dispatch(resetState());
      }, [5000]);
    }
  }, [isUpdated, error]);

  const handleChange = (event) => {
    if (event.target) {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    }
  };

  const notifications = [
    {
      name: 'Email Notifications',
      description:
        'You can enable your email for receiving updates on your prescriptions and order status.',
      switch: true,
      key: 'isEmailNotifActive',
    },
    {
      name: 'Phone Notifications',
      description: 'Stay informed via SMS or phone call alerts.',
      switch: false,
      key: 'isSmsNotifActive',
    },
    {
      name: 'Notification Sound',
      description: 'Customize the notification sound to distinguish alerts.',
      switch: false,
      key: 'isSoundNotifActive',
    },
  ];

  const handleChangeSwitch = (e, index) => {
    const { checked } = e.target;
    dispatch(
      updateAdminInfo({
        adminId: currentUser._id,
        data: {
          [notifications[index]?.key]: checked,
        },
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { first_name, last_name, _id: adminId } = data;
    dispatch(updateAdminInfo({ adminId, data: { first_name, last_name } }));
  };

  return (
    <div className={`general-wrapper ${isLoading ? 'disabled' : ''}`}>
      {(error.status || isUpdated) && (
        <Alert
          style={{
            position: 'fixed',
            top: '16px',
            right: '16px',
            zIndex: '100',
          }}
          severity={error.status ? 'error' : 'success'}
        >
          {error.status ? error.message : 'Changes saved successfully!'}
        </Alert>
      )}
      <div className="general-settings">
        <h1>General settings</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-row">
            <input
              style={{ border: '1px solid #DEDEDE' }}
              value={data?.first_name}
              onChange={handleChange}
              name="first_name"
              required
              placeholder="First Name*"
            />
            <input
              style={{ border: '1px solid #DEDEDE' }}
              value={data?.last_name}
              onChange={handleChange}
              name="last_name"
              required
              placeholder="Last Name*"
            />
            <input
              style={{ border: '1px solid #DEDEDE' }}
              value={data?.user_role}
              onChange={handleChange}
              name="role"
              disabled
              placeholder="Role*"
            />
          </div>
          <button className="light-green-button" type="submit">
            Save Changes
          </button>
        </form>
      </div>
      <div className="notification-wrapper">
        <h1>Notifications</h1>
        {notifications.map((notification, index) => (
          <div key={index} className="notification-div">
            <div className="inside-notification">
              <div className="left-side-notification">
                <h1>{notification.name}</h1>
                <p>{notification.description}</p>
              </div>
              <IOSSwitch
                checked={currentUser[notification.key]}
                onChange={(e) => handleChangeSwitch(e, index)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default General;

import React from 'react';
import { Avatar } from "@mui/material";
import { useSelector } from 'react-redux';


const DoctorInfo = () => {
    const currentUser = useSelector(state => state.user.currentUser);
    return (
        <div className='doctor-info-wrapper'>
            <div className='doctor'>
                <Avatar alt="Remy Sharp" src={ currentUser.profile_img ?? "/static/images/avatar/1.jpg"} sx={{ width: "70px", height: "70px" }} />
                <div className='doctor-info'>
                    <h1>Dr.{currentUser?.first_name}  {currentUser?.last_name}</h1>
                    <p>{currentUser?.user_role}</p>
                </div>
            </div>
        </div>
    );
};

export default DoctorInfo;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicRequest, userRequest } from '../utils/ApiConfig';

export const userLogin = createAsyncThunk('user/login', async (userData) => {
  try {
    const response = await publicRequest.post('/auth/admin/login', userData);
    return response.data;
  } catch (err) {
    if (err.response.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error('login Failed');
    }
  }
});

export const updateAdminInfo = createAsyncThunk(
  'admin/updateProfile',
  async ({ adminId, data }) => {
    try {
      const response = await userRequest.put(`/admin/${adminId}`, data);
      return response.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      }
      throw new Error('update admin info Failed');
    }
  }
);
export const updateAdminEmail = createAsyncThunk(
  'admin/updateEmail',
  async ({ adminId, email }) => {
    try {
      const response = await userRequest.put(`/admin/${adminId}/resetEmail`, {
        email: email,
      });
      return response.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      }
      throw new Error('update admin info Failed');
    }
  }
);
export const updateAdminPassword = createAsyncThunk(
  'admin/changePassword',
  async ({ adminId, password, newPassword }, { dispatch }) => {
    try {
      const response = await userRequest.put(
        `/admin/${adminId}/changepassword`,
        { password, newPassword }
      );
      return response?.message;
    } catch (err) {
      if (err?.response?.data) {
        throw new Error(err?.response?.data);
      }
      throw new Error('update password Failed');
    }
  }
);

export const logoutUser = () => (dispatch) => {
  // Perform any side effects here
  localStorage.clear(); // Corrected: `clear` method does not take arguments
  dispatch(userLogout()); // Dispatch the userLogout action to update the state
};

export const getAllAdminUsers = createAsyncThunk(
  'user/getAllAdmins',
  async (userData) => {
    try {
      const response = await publicRequest.get('/auth/admin/login', userData);
      return response.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      }
      throw new Error('get All admins failed');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    isLoading: false,
    loginError: {
      status: false,
      message: null,
    },
    error: {
      status: false,
      message: null,
    },
    successMssg: null,
    isRegistered: null,
    isUpdated: null,
  },
  reducers: {
    userLogout: (state) => {
      state.currentUser = null;
      localStorage.clear();
      state.error = {
        status: false,
        message: null,
      };
      state.isRegistered = null;
      state.isUpdated = null;
    },
    resetState: (state) => {
      state.isUpdated = false;
      state.error = {
        status: false,
        message: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        const { token, ...userWithoutToken } = action.payload;
        state.currentUser = userWithoutToken;
        state.isLoading = false;
        state.loginError = {
          status: false,
          message: null,
        };
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = {
          status: true,
          message: action.error.message || 'Login failed.',
        };
      })
      .addCase(updateAdminInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAdminInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        const currentUser = state.currentUser;
        state.currentUser = { ...currentUser, ...action.payload };
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateAdminInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'update user failed.',
        };
      })
      .addCase(updateAdminEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAdminEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        const currentUserData = state.currentUser;
        state.currentUser = { ...currentUserData, ...action.payload };
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateAdminEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'update user failed.',
        };
      })
      .addCase(updateAdminPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAdminPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.successMssg = action.payload?.message;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateAdminPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error?.message || 'Failed updating password.',
        };
      });
  },
});
export const { userLogout, resetState } = userSlice.actions;
export const userReducer = userSlice.reducer;

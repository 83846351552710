import React, { useEffect, useRef } from 'react';
import { Modal, Button } from '@mui/material';
import './PrintModal.scss';
import ReactToPrint from 'react-to-print';

const PrintModal = ({ open, setOpen, images }) => {
  const ref = useRef();
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="modal-print">
        <ReactToPrint
          bodyClass="print-agreement"
          content={() => ref.current}
          trigger={() => (
            <Button className="print-bttn" onClick={() => window.print()}>
              print
            </Button>
          )}
        />
        <div ref={ref}>{images.map((url) => url && <img src={url} />)}</div>
      </div>
    </Modal>
  );
};

export default PrintModal;

import React, { useState } from 'react';
import SendPrescriptionModal from './SendPrescriptionModal';
import ConfirmPrescriptionModal from './ConfirmPrescriptionModal';
import { useSelector, useDispatch } from 'react-redux';
import {
  createPrescriptionOrder,
  updatePrescriptionById,
} from '../redux/prescriptionSlice';

const ConfirmArchiveCancelButtons = ({ tax }) => {
  const dispatch = useDispatch();
  const [sendPrescription, setSendPrescription] = useState(false);
  const [confirmPurchase, setConfirmPurchase] = useState(false);
  const { prescriptionDetails } = useSelector((state) => state.prescription);

  const handleSendPrescription = () => {
    setSendPrescription(false);
    setConfirmPurchase(true);
    dispatch(
      createPrescriptionOrder({ prescriptionId: prescriptionDetails._id, tax })
    );
  };

  const archivePrescription = () => {
    dispatch(
      updatePrescriptionById({
        id: prescriptionDetails._id,
        newData: { isArchived: true },
      })
    );
  };
  return (
    <>
      <ConfirmPrescriptionModal
        open={confirmPurchase}
        handleClose={() => setConfirmPurchase(false)}
      />
      <SendPrescriptionModal
        open={sendPrescription}
        handleClose={() => setSendPrescription(false)}
        handleSendPrescription={handleSendPrescription}
      />
      <div className="buttons-wrapper">
        <button className="button1">Cancel</button>
        <div className="right-side-buttons">
          <button className="button2" onClick={archivePrescription}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M13.334 2.73438H2.66732C1.93094 2.73438 1.33398 3.33133 1.33398 4.06771V4.73437C1.33398 5.47075 1.93094 6.06771 2.66732 6.06771H13.334C14.0704 6.06771 14.6673 5.47075 14.6673 4.73437V4.06771C14.6673 3.33133 14.0704 2.73438 13.334 2.73438Z"
                stroke="#219D50"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.66602 6.06641V12.0664C2.66602 12.42 2.80649 12.7592 3.05654 13.0092C3.30659 13.2593 3.64573 13.3997 3.99935 13.3997H11.9993C12.353 13.3997 12.6921 13.2593 12.9422 13.0092C13.1922 12.7592 13.3327 12.42 13.3327 12.0664V6.06641"
                stroke="#219D50"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66602 8.73438H9.33268"
                stroke="#219D50"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Archive
          </button>
          <button onClick={() => setSendPrescription(true)}>
            Send Prescription to Patient for Payment
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmArchiveCancelButtons;

import './MobilePrescriptionTable.scss';
import React from 'react';
import { Divider, IconButton } from '@mui/material';
import CityCheckbox from './CityCheckbox';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/callBack';

const MobilePrescriptionTable = ({
  data,
  checkedPrescriptions,
  handleCheckPrescriptions,
  unarchiveOne,
  isArchive = false,
}) => {
  const navigate = useNavigate();
  return (
    <div className="mobile-prescription-table-wrapper">
      {data.map((prescription, index) => {
        const isChecked = checkedPrescriptions.some(
          (checkedPresc) => checkedPresc?._id === prescription?._id
        );
        return (
          <>
            <div className="information-wrapper">
              <div className="name-part">
                <div className="top-part">
                  <div className="left-side">
                    <CityCheckbox
                      checked={isChecked}
                      onClick={(event) =>
                        handleCheckPrescriptions(event, prescription)
                      }
                    />
                    <h1>#{prescription.number}</h1>
                    {prescription.new && (
                      <div className="light-green-box">
                        <p>New</p>
                      </div>
                    )}
                    <div className="grey-box">
                      <p>{prescription.type}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <div
                      className={
                        prescription.status === 'Processing'
                          ? 'grey-box2'
                          : prescription.status === 'Paid' ||
                            prescription.status === 'Delivered'
                          ? 'green-box2'
                          : prescription.status === 'Cancelled'
                          ? 'red-box'
                          : 'light-green-box'
                      }
                    >
                      {prescription.status}
                    </div>
                    {/* <IconButton sx={{ padding: '0px' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton> */}
                  </div>
                </div>
                <div className="prescription-information">
                  <p>
                    {prescription?.patient_fname} {prescription?.patient_lname}
                  </p>
                  <Divider
                    sx={{ height: '10px' }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <p>{formatDate(prescription?.createdAt)}</p>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M11.0007 8.96005V10.46C11.0013 10.5993 10.9727 10.7371 10.917 10.8647C10.8612 10.9923 10.7793 11.1068 10.6767 11.201C10.5741 11.2951 10.453 11.3668 10.3211 11.4114C10.1892 11.456 10.0494 11.4726 9.9107 11.46C8.37212 11.2929 6.8942 10.7671 5.5957 9.92505C4.38761 9.15738 3.36337 8.13313 2.5957 6.92505C1.75069 5.62065 1.22482 4.13555 1.0607 2.59005C1.0482 2.45178 1.06464 2.31243 1.10895 2.18086C1.15326 2.04929 1.22448 1.92839 1.31808 1.82586C1.41168 1.72332 1.5256 1.6414 1.65259 1.58531C1.77959 1.52922 1.91687 1.50018 2.0557 1.50005H3.5557C3.79835 1.49766 4.03359 1.58359 4.21758 1.74181C4.40156 1.90004 4.52174 2.11977 4.5557 2.36005C4.61901 2.84008 4.73642 3.31141 4.9057 3.76505C4.97297 3.94401 4.98753 4.13851 4.94765 4.32549C4.90777 4.51247 4.81513 4.6841 4.6807 4.82005L4.0457 5.45505C4.75748 6.70682 5.79393 7.74327 7.0457 8.45505L7.6807 7.82005C7.81664 7.68562 7.98828 7.59297 8.17526 7.5531C8.36224 7.51322 8.55674 7.52778 8.7357 7.59505C9.18934 7.76432 9.66067 7.88174 10.1407 7.94505C10.3836 7.97931 10.6054 8.10165 10.764 8.2888C10.9225 8.47594 11.0068 8.71484 11.0007 8.96005Z"
                        stroke="#D3D3D3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>{prescription?.phone}</p>
                  </div>
                </div>
                {isArchive ? (
                  <button
                    className="white-button"
                    onClick={() => unarchiveOne(prescription)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M10.5 9.33268L13.8333 5.99935L10.5 2.66602"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.8327 6H6.83268C5.86022 6 4.92759 6.38631 4.23996 7.07394C3.55232 7.76157 3.16602 8.69421 3.16602 9.66667C3.16602 10.1482 3.26086 10.625 3.44512 11.0698C3.62939 11.5147 3.89948 11.9189 4.23996 12.2594C4.92759 12.947 5.86022 13.3333 6.83268 13.3333H9.16602"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Unarchive
                  </button>
                ) : (
                  <button
                    className="green-button"
                    onClick={() =>
                      navigate(`/prescription/${prescription.number}`)
                    }
                  >
                    Review
                  </button>
                )}
              </div>
            </div>
            {index !== data.length - 1 && <Divider sx={{ width: '100%' }} />}
          </>
        );
      })}
    </div>
  );
};

export default MobilePrescriptionTable;

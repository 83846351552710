import { IconButton } from '@mui/material';
import React from 'react';
import './NotificationAlertList.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import prescIcon from '../assets/prescIcon.svg';
import mssgIcon from '../assets/msgIcon.svg';
import orderIcon from '../assets/order.svg';
import { formatDate } from '../utils/callBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotification,
  updateNotification,
} from '../redux/NotificationSlice';
import { useNavigate } from 'react-router-dom';

const NotificationAlertList = ({ data, page }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const getIconType = (type) => {
    switch (type) {
      case 'prescription':
        return <img src={prescIcon} alt="notif-icon" />;
      case 'message':
        return <img src={mssgIcon} alt="notif-icon" />;
      case 'order':
        return <img src={orderIcon} alt="notif-icon" />;
    }
  };

  const handleDelete = (e, notId) => {
    e.stopPropagation();
    dispatch(
      deleteNotification({
        notificationId: notId,
        page: page,
      })
    );
  };

  const handleClick = async (notification) => {
    if (!notification?.is_read) {
      await dispatch(
        updateNotification({
          userId: currentUser?._id,
          notificationId: notification?._id,
          page: page,
        })
      );
    }
    if (notification?.type !== 'order') {
      navigate(`/prescription/${notification?.ref_id}`);
    }
  };

  return (
    <div
      className={`notificationAlertList-box ${data?.is_read ? '' : 'new'}`}
      onClick={() => handleClick(data)}
    >
      <div className="left">
        <div className="notif-icon-box">{getIconType(data.type)}</div>
        <div className="notif-data">
          <p className="notif-title">{data?.content}</p>
          <p className="notif-detail">
            {data?.type} - {formatDate(data?.createdAt)}
          </p>
        </div>
      </div>
      <IconButton
        className="close-bttn"
        onClick={(e) => handleDelete(e, data?._id)}
      >
        <CloseOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default NotificationAlertList;

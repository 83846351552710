import React from 'react';
import CityCheckbox from './CityCheckbox';
import { Divider, IconButton } from '@mui/material';
import './MobilePrescriptionTable.scss';
import { useNavigate } from 'react-router-dom';

const MobileCustomersTable = ({
  data,
  checkedCustomers,
  handleCheckedCustomer,
  setOpenDeleteCustomerModal,
  setIndexToDelete,
}) => {
  const navigate = useNavigate();
  return (
    <div className="mobile-prescription-table-wrapper">
      {data?.map((customer, index) => {
        const isChecked = checkedCustomers.some(
          (checkedCustom) => checkedCustom._id === customer._id
        );
        return (
          <div key={customer._id}>
            <div className="information-wrapper">
              <div className="name-part">
                <div className="top-part">
                  <div className="left-side">
                    <CityCheckbox
                      checked={isChecked}
                      onClick={(event) =>
                        handleCheckedCustomer(event, customer)
                      }
                    />
                    <h1>{customer.name}</h1>
                    {customer.new && (
                      <div className="light-green-box">
                        <p>New</p>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <div
                      className={customer.isActive ? 'green-box2' : 'red-box'}
                    >
                      {customer?.isActive ? 'Active' : 'Inactive'}
                    </div>
                    <IconButton sx={{ padding: '0px' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        {/* SVG paths */}
                      </svg>
                    </IconButton>
                  </div>
                </div>
                <div className="prescription-information customer">
                  <p>{customer.email}</p>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ height: '10px' }}
                  />
                  <p>{customer?.telephone}</p>
                </div>
                <div className="mobile-buttons-customers">
                  <button
                    onClick={() =>
                      navigate(`/customers/history/${customer._id}`)
                    }
                    className="white-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M2.5 2.5V5.83333H5.83333"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.53333 9.1664C2.681 10.5036 3.2738 11.7524 4.21652 12.7122C5.15924 13.672 6.39718 14.2871 7.73153 14.4588C9.06587 14.6304 10.4192 14.3487 11.5741 13.6587C12.7291 12.9688 13.6187 11.9107 14.1 10.6544C14.5813 9.39808 14.6264 8.01647 14.2281 6.73145C13.8298 5.44642 13.0112 4.33254 11.9037 3.5687C10.7962 2.80485 9.46419 2.43537 8.12148 2.51958C6.77878 2.6038 5.50334 3.13683 4.5 4.03307L2.5 5.83307"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.5 5.16797V8.5013L11.1667 9.83464"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    History
                  </button>
                  <button
                    onClick={() =>
                      navigate(`/customers/details/${customer._id}`)
                    }
                    className="white-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_3877_49215)">
                        <path
                          d="M10.167 1.83203H4.50033C4.1467 1.83203 3.80756 1.97251 3.55752 2.22256C3.30747 2.4726 3.16699 2.81174 3.16699 3.16536V13.832C3.16699 14.1857 3.30747 14.5248 3.55752 14.7748C3.80756 15.0249 4.1467 15.1654 4.50033 15.1654H12.5003C12.8539 15.1654 13.1931 15.0249 13.4431 14.7748C13.6932 14.5248 13.8337 14.1857 13.8337 13.832V5.4987L10.167 1.83203Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.83301 1.83203V5.83203H13.833"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3877_49215">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.5 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Details
                  </button>
                </div>
              </div>
            </div>
            {index !== data.length - 1 && <Divider sx={{ width: '100%' }} />}
          </div>
        );
      })}
    </div>
  );
};

export default MobileCustomersTable;

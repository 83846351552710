import React, {useState} from 'react';
import './MemberSettings.scss'
import {IconButton, InputAdornment, InputBase} from "@mui/material";
import CityCheckbox from "../../components/CityCheckbox";
import {useNavigate} from "react-router-dom";
import MemberSettingsSmallTable from "../../components/MemberSettingsSmallTable";

const MemberSettings = () => {
    const navigate = useNavigate();
    const [members, setMembers] = useState([
        {
            name: "Jane Harris1",
            email: "janeharris@gmail.com",
            role: "Admin",
            joined_on: "12 Nov 2021",
            status: "active",
            new:true
        },
        {
            name: "Jane Harris2",
            email: "janeharris@gmail.com",
            role: "Manager",
            joined_on: "12 Nov 2021",
            status: "inactive"
        },
        {
            name: "Jane Harris3",
            email: "janeharris@gmail.com",
            role: "Pharmacist",
            joined_on: "12 Nov 2021",
            status: "inactive"
        },
        {
            name: "Jane Harris4",
            email: "janeharris@gmail.com",
            role: "Delivery Man",
            joined_on: "12 Nov 2021",
            status: "active"
        },
        {
            name: "Jane Harris5",
            email: "janeharris@gmail.com",
            role: "Salesman",
            joined_on: "12 Nov 2021",
            status: "active"
        },
        {
            name: "Jane Harris6",
            email: "janeharris@gmail.com",
            role: "Pharmacist",
            joined_on: "12 Nov 2021",
            status: "active"
        },
        {
            name: "Jane Harris7",
            email: "janeharris@gmail.com",
            role: "Pharmacist",
            joined_on: "12 Nov 2021",
            status: "inactive"
        },
        {
            name: "Jane Harris8",
            email: "janeharris@gmail.com",
            role: "Pharmacist",
            joined_on: "12 Nov 2021",
            status: "active"
        },
        {
            name: "Jane Harris9",
            email: "janeharris@gmail.com",
            role: "Pharmacist",
            joined_on: "12 Nov 2021",
            status: "active"
        },
    ]);
    const [checkedMembers, setCheckedMembers] = useState([]);
    const handleCheckCustomer = (event, customer) => {
        if (event.target.checked) {
            setCheckedMembers(prevState => [...prevState, customer]);
        } else {
            setCheckedMembers(prevState =>
                prevState.filter(presc => presc.name !== customer.name)
            );
        }
    };

    const handleDeleteMember = (index) => {
        const updatedMembers = [...members];
        updatedMembers.splice(index, 1);
        setMembers(updatedMembers);
    }


    const selectAllMembers = (event) => {
        if (event.target.checked) {
            setCheckedMembers(members)
        } else
            setCheckedMembers([])
    }

    const handleDeleteCheckedMembers = () => {
        const updatedCustomers = members.filter((members) => !checkedMembers.includes(members));
        setMembers(updatedCustomers);
        setCheckedMembers([])
    };

    return (
        <div className='member-settings-wrapper'>
            <div className='top-part'>
                <h1>Member Settings</h1>
                <InputBase
                    className='mui-register-password'
                    type='text'
                    placeholder='Search in prescriptions'
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                                        stroke="#616161" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M13.9996 13.9996L11.0996 11.0996" stroke="#616161" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <button className='white-button'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M13.3337 4.92969L6.00033 12.263L2.66699 8.92969" stroke="black" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Multi-select
                </button>
                <button className='green-button' onClick={() => navigate("/settings/member-settings/add-member")}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M8 4.26172V13.5951" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M3.33301 8.92969H12.6663" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                    Add Team
                </button>
            </div>
            {checkedMembers.length > 0 &&
                <div className='items-selected'>
                    <div className='left-side-items-selected'>
                        <p>
                            {checkedMembers.length} items selected :
                        </p>
                        <div className='buttons-wrapper-customer'>
                            <button className='white-button' onClick={handleDeleteCheckedMembers}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                     fill="none">
                                    <g clipPath="url(#clip0_3877_51290)">
                                        <path d="M2 4.5H14" stroke="black" strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                        <path
                                            d="M12.6663 4.5V13.8333C12.6663 14.5 11.9997 15.1667 11.333 15.1667H4.66634C3.99967 15.1667 3.33301 14.5 3.33301 13.8333V4.5"
                                            stroke="black" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M5.33301 4.4987V3.16536C5.33301 2.4987 5.99967 1.83203 6.66634 1.83203H9.33301C9.99967 1.83203 10.6663 2.4987 10.6663 3.16536V4.4987"
                                            stroke="black" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3877_51290">
                                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Delete
                            </button>
                            <button className='white-button'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                     fill="none">
                                    <g clipPath="url(#clip0_3877_46302)">
                                        <path
                                            d="M7.99967 15.1654C11.6816 15.1654 14.6663 12.1806 14.6663 8.4987C14.6663 4.8168 11.6816 1.83203 7.99967 1.83203C4.31778 1.83203 1.33301 4.8168 1.33301 8.4987C1.33301 12.1806 4.31778 15.1654 7.99967 15.1654Z"
                                            stroke="black" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path d="M10 6.5L6 10.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                        <path d="M6 6.5L10 10.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3877_46302">
                                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Inactive
                            </button>
                        </div>
                    </div>
                    <IconButton>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M18 6.5L6 18.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M6 6.5L18 18.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    </IconButton>
                </div>
            }
            <table>
                <thead>
                <tr>
                    <th>
                        <div className='inside-cell'>
                            <CityCheckbox onClick={selectAllMembers}/>
                            <p>Name</p>
                        </div>
                    </th>
                    <th>E-mail</th>
                    <th>Role</th>
                    <th>Joined On</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>
                {members.map((member, index) => {
                    const isChecked = checkedMembers.some(
                        (checkedMemb) => checkedMemb.name === member.name
                    );
                    return (
                        <tbody key={index}>
                        <tr className={isChecked && 'checked'}>
                            <td>
                                <div className='inside-cell'>
                                    <CityCheckbox checked={isChecked}
                                                  onClick={(event) => handleCheckCustomer(event, member)}/>
                                    <p>{member.name}</p>
                                </div>
                            </td>
                            <td>
                                <p>{member.email}</p>
                            </td>
                            <td>{member.role}</td>
                            <td>
                                {member.joined_on}
                            </td>
                            <td>
                                <div
                                    className={member.status === 'active' ? "green-box2" : "red-box"}>
                                    <p>{member.status}</p>
                                </div>
                            </td>
                            <td>
                                <IconButton>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21"
                                         fill="none">
                                        <g clipPath="url(#clip0_1598_58685)">
                                            <path
                                                d="M9.16602 4.26562H3.33268C2.89065 4.26563 2.46673 4.44122 2.15417 4.75378C1.84161 5.06634 1.66602 5.49026 1.66602 5.93229V17.599C1.66602 18.041 1.84161 18.4649 2.15417 18.7775C2.46673 19.09 2.89065 19.2656 3.33268 19.2656H14.9993C15.4414 19.2656 15.8653 19.09 16.1779 18.7775C16.4904 18.4649 16.666 18.041 16.666 17.599V11.7656"
                                                stroke="#5C5F62" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path
                                                d="M15.416 3.01386C15.7475 2.68234 16.1972 2.49609 16.666 2.49609C17.1349 2.49609 17.5845 2.68234 17.916 3.01386C18.2475 3.34538 18.4338 3.79502 18.4338 4.26386C18.4338 4.7327 18.2475 5.18234 17.916 5.51386L9.99935 13.4305L6.66602 14.2639L7.49935 10.9305L15.416 3.01386Z"
                                                stroke="#5C5F62" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1598_58685">
                                                <rect width="20" height="20" fill="white"
                                                      transform="translate(0 0.929688)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </IconButton>
                                <IconButton onClick={() => handleDeleteMember(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21"
                                         fill="none">
                                        <g clipPath="url(#clip0_1598_58246)">
                                            <path d="M2.5 5.92969H17.5" stroke="#5C5F62" strokeWidth="1.875"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M15.8327 5.92969V17.5964C15.8327 18.4297 14.9994 19.263 14.166 19.263H5.83268C4.99935 19.263 4.16602 18.4297 4.16602 17.5964V5.92969"
                                                stroke="#5C5F62" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path
                                                d="M6.66602 5.92708V4.26042C6.66602 3.42708 7.49935 2.59375 8.33268 2.59375H11.666C12.4993 2.59375 13.3327 3.42708 13.3327 4.26042V5.92708"
                                                stroke="#5C5F62" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M8.33398 10.0938V15.0937" stroke="#5C5F62" strokeWidth="1.875"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M11.666 10.0938V15.0937" stroke="#5C5F62" strokeWidth="1.875"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1598_58246">
                                                <rect width="20" height="20" fill="white"
                                                      transform="translate(0 0.929688)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </IconButton>
                            </td>
                        </tr>
                        </tbody>
                    );
                })}
            </table>
        {/*    Table for small displays*/}
            <MemberSettingsSmallTable data={members} handleCheckedCustomer={handleCheckCustomer} checkedCustomers={checkedMembers} handleDeleteMember={handleDeleteMember} />
        </div>
    );
};

export default MemberSettings;
import React from 'react';
import {Checkbox} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const CityCheckbox = (props) => {
    return (
        <Checkbox sx={{padding:"0px !important"}} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon sx={{color:"green"}} />} {...props} />
    );
};

export default CityCheckbox;
import { logoutUser } from '../redux/userSlice';
import { publicRequest } from './ApiConfig';

// user our server to store file and generate a fileurl
const getFileUrl = async (file) => {
  try {
    const form = new FormData();
    form.append('file', file);
    const response = await publicRequest.post('/fileupload', form);
    const fileUrl = response.data.location;
    return fileUrl;
  } catch (err) {
    return false;
  }
};

const formatDate = (isoDateString) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(isoDateString);
  const formattedDate = date.toLocaleString('en-US', options);
  if (formattedDate !== 'Invalid Date') {
    return formattedDate;
  } else {
    return 'N/A';
  }
};

const isEmailValid = (email) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
};

const checkPasswordStrength = (passwordCheck) => {
  // Check length
  const hasLength = passwordCheck.length >= 8;

  // Check lowercase
  const hasLowercase = /[a-z]/.test(passwordCheck);

  // Check uppercase
  const hasUppercase = /[A-Z]/.test(passwordCheck);

  // Check symbol
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(passwordCheck);

  // Check all conditions
  return hasLength && hasLowercase && hasUppercase && hasSymbol;
};

const handleAutoLogout = (dispatch) => {
  dispatch(logoutUser());
};

export {
  getFileUrl,
  formatDate,
  handleAutoLogout,
  checkPasswordStrength,
  isEmailValid,
};

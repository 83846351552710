import React, { useEffect, useState } from 'react';
import './CustomerHistory.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CityCheckbox from '../../components/CityCheckbox';
import MobileCustomerHistoryTable from '../../components/MobileCustomerHistoryTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPrescriptionByCustomerId,
  updateBulkPrescriptions,
} from '../../redux/prescriptionSlice';
import { formatDate } from '../../utils/callBack';
import { CircularProgress, Box, Alert } from '@mui/material';

const CustomerHistory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedPrescriptions, setCheckedPrescriptions] = useState([]);
  const prescriptions =
    useSelector((state) => state.prescription?.customerPrescriptions) ?? [];
  const { isLoading, error } = useSelector((state) => state.prescription);

  useEffect(() => {
    dispatch(getPrescriptionByCustomerId(id));
  }, [id]);

  const handleCheckPrescriptions = (event, prescription) => {
    if (event.target.checked) {
      setCheckedPrescriptions((prevState) => [...prevState, prescription]);
    } else {
      setCheckedPrescriptions((prevState) =>
        prevState.filter((presc) => presc._id !== prescription._id)
      );
    }
  };
  const selectAllPrescriptions = (event) => {
    if (event.target.checked) {
      setCheckedPrescriptions(prescriptions);
    } else {
      setCheckedPrescriptions([]);
    }
  };
  const archive = () => {
    const ids = checkedPrescriptions.map((prescription) => prescription._id);
    dispatch(updateBulkPrescriptions({ ids, updates: { isArchived: true } }));
    setCheckedPrescriptions([]);
  };

  return (
    <div className="customer-history-wrapper">
      {error.status && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            bottom: '16px',
            right: '16px',
            zIndex: '50',
          }}
        >
          {error.message}
        </Alert>
      )}
      <button className="back-button" onClick={() => navigate('/customers')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.6673 8H3.33398"
            stroke="#219D50"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00065 12.6673L3.33398 8.00065L8.00065 3.33398"
            stroke="#219D50"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Back
      </button>
      <div className="top-part">
        <div className="left-side-customer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="27"
            viewBox="0 0 26 27"
            fill="none"
          >
            <path
              d="M3.25 3.92969V9.34635H8.66667"
              stroke="black"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.30417 14.7616C3.54413 16.9346 4.50743 18.9639 6.03935 20.5235C7.57127 22.0832 9.58292 23.0828 11.7512 23.3617C13.9195 23.6407 16.1187 23.1828 17.9955 22.0616C19.8723 20.9404 21.3178 19.2211 22.0999 17.1796C22.8821 15.1381 22.9554 12.893 22.3082 10.8048C21.6609 8.71664 20.3306 6.90659 18.531 5.66534C16.7314 4.42409 14.5668 3.82368 12.3849 3.96054C10.203 4.09739 8.13043 4.96356 6.5 6.41995L3.25 9.34495"
              stroke="black"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 8.26172V13.6784L17.3333 15.8451"
              stroke="black"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>History</p>
        </div>
        {/* <InputBase
                    className="mui-register-password"
                    type="text"
                    placeholder="Search among prescriptions"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton edge="end">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                                        stroke="#616161"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M13.9996 13.9996L11.0996 11.0996"
                                        stroke="#616161"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </IconButton>
                        </InputAdornment>
                    }
                /> */}
        <div className="buttons-wrapper-customer">
          <button
            className="white-button"
            onClick={() => navigate(`/customers/details/${id}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <g clipPath="url(#clip0_3899_56095)">
                <path
                  d="M9.66602 2.01172H3.99935C3.64573 2.01172 3.30659 2.15219 3.05654 2.40224C2.80649 2.65229 2.66602 2.99143 2.66602 3.34505V14.0117C2.66602 14.3653 2.80649 14.7045 3.05654 14.9545C3.30659 15.2046 3.64573 15.3451 3.99935 15.3451H11.9993C12.353 15.3451 12.6921 15.2046 12.9422 14.9545C13.1922 14.7045 13.3327 14.3653 13.3327 14.0117V5.67839L9.66602 2.01172Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.33398 2.01172V6.01172H13.334"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3899_56095">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.679688)"
                  />
                </clipPath>
              </defs>
            </svg>
            Details
          </button>
          {/* <button className="red-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <g clipPath="url(#clip0_3899_47479)">
                <path
                  d="M2 4.5H14"
                  stroke="#CD1D1D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.6673 4.5V13.8333C12.6673 14.5 12.0007 15.1667 11.334 15.1667H4.66732C4.00065 15.1667 3.33398 14.5 3.33398 13.8333V4.5"
                  stroke="#CD1D1D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.33398 4.4987V3.16536C5.33398 2.4987 6.00065 1.83203 6.66732 1.83203H9.33398C10.0007 1.83203 10.6673 2.4987 10.6673 3.16536V4.4987"
                  stroke="#CD1D1D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3899_47479">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            Delete
          </button> */}
        </div>
      </div>
      {isLoading && (
        <Box
          style={{
            height: '60vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <>
            <CircularProgress color="success" />
            <span>Loading Data...</span>{' '}
          </>
        </Box>
      )}
      {checkedPrescriptions.length > 0 && !isLoading && (
        <div className="items-selected">
          <div className="left-side-items-selected">
            <p>{checkedPrescriptions.length} items selected :</p>
            <div className="buttons-wrapper-customer">
              <button className="white-button" onClick={archive}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M14.6673 8.67969H10.6673L9.33398 10.6797H6.66732L5.33398 8.67969H1.33398"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.63398 4.08766L1.33398 8.68099V12.681C1.33398 13.0346 1.47446 13.3737 1.72451 13.6238C1.97456 13.8738 2.3137 14.0143 2.66732 14.0143H13.334C13.6876 14.0143 14.0267 13.8738 14.2768 13.6238C14.5268 13.3737 14.6673 13.0346 14.6673 12.681V8.68099L12.3673 4.08766C12.2569 3.86551 12.0868 3.67857 11.876 3.54784C11.6651 3.41712 11.422 3.34779 11.174 3.34766H4.82732C4.57926 3.34779 4.33616 3.41712 4.12535 3.54784C3.91453 3.67857 3.74437 3.86551 3.63398 4.08766Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Archive
              </button>
            </div>
          </div>
          <IconButton onClick={() => setCheckedPrescriptions([])}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M18 6.5L6 18.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6.5L18 18.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
      )}
      {!isLoading && (
        <table>
          <thead>
            <tr>
              <th>
                <div className="inside-cell">
                  <CityCheckbox
                    onClick={selectAllPrescriptions}
                    checked={
                      checkedPrescriptions?.length === prescriptions?.length &&
                      checkedPrescriptions?.length !== 0
                    }
                  />
                  <p>Prescription</p>
                </div>
              </th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          {prescriptions.map((prescription, index) => {
            const isChecked = checkedPrescriptions.some(
              (checkedPresc) => checkedPresc._id === prescription._id
            );
            return (
              <tbody key={index}>
                <tr>
                  <td style={{ width: '35%' }}>
                    <div className="inside-cell">
                      <CityCheckbox
                        checked={isChecked}
                        onClick={(event) =>
                          handleCheckPrescriptions(event, prescription)
                        }
                      />
                      <p>
                        {prescription.number ? '#' + prescription.number : 'NA'}
                      </p>
                      {prescription?.new && (
                        <div className="green-box">
                          <p className="green">New</p>
                        </div>
                      )}
                    </div>
                  </td>
                  <td style={{ width: '30%' }}>
                    <p>{formatDate(prescription.createdAt)}</p>
                  </td>
                  <td style={{ width: '40%' }}>
                    <div
                      className={
                        prescription.status === 'Processing'
                          ? 'grey-box2'
                          : prescription.status === 'Paid' ||
                            prescription.status === 'Delivered'
                          ? 'green-box2'
                          : prescription.status === 'Cancelled'
                          ? 'red-box'
                          : 'light-green-box'
                      }
                    >
                      <p>{prescription.status}</p>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <button
                        className="green-button"
                        onClick={() =>
                          navigate(`/prescription/${prescription._id}`)
                        }
                      >
                        Review
                      </button>
                      {/* <IconButton>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M12 13.6797C12.5523 13.6797 13 13.232 13 12.6797C13 12.1274 12.5523 11.6797 12 11.6797C11.4477 11.6797 11 12.1274 11 12.6797C11 13.232 11.4477 13.6797 12 13.6797Z"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 6.67969C12.5523 6.67969 13 6.23197 13 5.67969C13 5.1274 12.5523 4.67969 12 4.67969C11.4477 4.67969 11 5.1274 11 5.67969C11 6.23197 11.4477 6.67969 12 6.67969Z"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 20.6797C12.5523 20.6797 13 20.232 13 19.6797C13 19.1274 12.5523 18.6797 12 18.6797C11.4477 18.6797 11 19.1274 11 19.6797C11 20.232 11.4477 20.6797 12 20.6797Z"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </IconButton> */}
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      )}
      {/*    Small Display Table*/}
      <MobileCustomerHistoryTable
        data={prescriptions}
        checkedPrescriptions={checkedPrescriptions}
        handleCheckPrescriptions={handleCheckPrescriptions}
      />
    </div>
  );
};

export default CustomerHistory;

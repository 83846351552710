import React from 'react';
import {IconButton, Modal} from "@mui/material";
import './Modal.scss'

const CityModal = ({name, open, handleClose, content}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{xs: {width: "100%"}}}
        >
            <div className='modal'>
                <div className='top-part'>
                    <h1>{name}</h1>
                    <IconButton onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                            <path d="M27.9961 13L11.9961 29.0005" stroke="#979797" strokeWidth="1.28573"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.9961 13L27.9961 29.0005" stroke="#979797" strokeWidth="1.28573"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </IconButton>
                </div>
                {content}
            </div>
        </Modal>
    );
};

export default CityModal;
import React from 'react';
import LeftSide from '../../components/Login-ForgetPasswordComponentsAdmin/LeftSide';
import RightSide from '../../components/Login-ForgetPasswordComponentsAdmin/RightSide';
import './ForgetPassword.scss';

const ChangePassword = () => {
  return (
    <div className="forget-passwordAdmin-wrapper">
      <div className="forget-password">
        <RightSide />
        <LeftSide />
      </div>
    </div>
  );
};

export default ChangePassword;

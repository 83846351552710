import React from 'react'

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';


const AuthRequired = ({ children }) => {
  const { currentUser } = useSelector((state) => state.user);
  const token = localStorage.getItem('token');
  if (currentUser && token) return <>{children}</>;
  return <Navigate to={'/auth/login'} />
}

export default AuthRequired;

import React, {useState} from 'react';
import './NavigationLinks.scss';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {Link, useLocation, useParams} from 'react-router-dom';
import {Accordion, AccordionDetails, AccordionSummary, Button, Typography,} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NavigationLinks = () => {
    const location = useLocation();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const pathname = location.pathname;
    const {orderId} = useParams();
    const [isLosgoutOpen, setIsLosgoutOpen] = useState(false);

    const handleShowSettings = (e) => {
        e.stopPropagation();
        setSettingsOpen(!settingsOpen);
    };

    return (
        <div className="bottom-user-cont-navigation-links">
            <Link
                to="/prescription"
                className={`dashboard-nav-link ${
                    pathname === '/prescription' ? 'active' : ''
                }`}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path
                        d="M14.5 2.5H6C5.46957 2.5 4.96086 2.71071 4.58579 3.08579C4.21071 3.46086 4 3.96957 4 4.5V20.5C4 21.0304 4.21071 21.5391 4.58579 21.9142C4.96086 22.2893 5.46957 22.5 6 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V8L14.5 2.5Z"
                        stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14 2.5V8.5H20" stroke="black" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M16 13.5H8" stroke="black" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M16 17.5H8" stroke="black" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M10 9.5H8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Prescriptions
            </Link>
            <Link
                to="/customers"
                className={`dashboard-nav-link ${
                    pathname === '/customers' ? 'active' : ''
                }`}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path
                        d="M16 21.5V19.5C16 18.4391 15.5786 17.4217 14.8284 16.6716C14.0783 15.9214 13.0609 15.5 12 15.5H6C4.93913 15.5 3.92172 15.9214 3.17157 16.6716C2.42143 17.4217 2 18.4391 2 19.5V21.5"
                        stroke="#393939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z"
                        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M22 21.5009V19.5009C21.9993 18.6146 21.7044 17.7536 21.1614 17.0532C20.6184 16.3527 19.8581 15.8524 19 15.6309"
                        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M16 3.63086C16.8604 3.85116 17.623 4.35156 18.1676 5.05317C18.7122 5.75478 19.0078 6.61769 19.0078 7.50586C19.0078 8.39403 18.7122 9.25694 18.1676 9.95855C17.623 10.6602 16.8604 11.1606 16 11.3809"
                        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Customers
            </Link>
            <Accordion expanded={settingsOpen} onChange={handleShowSettings}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <SettingsOutlinedIcon/>
                    <Typography>Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Link
                        to="/settings/general"
                        className={`dashboard-nav-link ${
                            pathname === '/settings/general' ? 'active' : ''
                        }`}
                    >
                        <TuneOutlinedIcon/>
                        General
                    </Link>
                    {/* <Link
                        to="/settings/member-settings"
                        className={`dashboard-nav-link ${
                            pathname === '/settings/member-settings' ? 'active' : ''
                        }`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path
                                d="M16.5 21.5V19.5C16.5 18.4391 16.0786 17.4217 15.3284 16.6716C14.5783 15.9214 13.5609 15.5 12.5 15.5H6.5C5.43913 15.5 4.42172 15.9214 3.67157 16.6716C2.92143 17.4217 2.5 18.4391 2.5 19.5V21.5"
                                stroke="#393939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9.5 11.5C11.7091 11.5 13.5 9.70914 13.5 7.5C13.5 5.29086 11.7091 3.5 9.5 3.5C7.29086 3.5 5.5 5.29086 5.5 7.5C5.5 9.70914 7.29086 11.5 9.5 11.5Z"
                                stroke="#393939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M19.5 13.5C20.6046 13.5 21.5 12.6046 21.5 11.5C21.5 10.3954 20.6046 9.5 19.5 9.5C18.3954 9.5 17.5 10.3954 17.5 11.5C17.5 12.6046 18.3954 13.5 19.5 13.5Z"
                                stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19.5 8.5V9.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M19.5 13.5V14.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M22.1005 10L21.2305 10.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M17.7704 12.5L16.9004 13" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M22.1005 13L21.2305 12.5" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M17.7704 10.5L16.9004 10" stroke="black" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                        Team Members
                    </Link> */}
                    <Link
                        to="/settings/security"
                        className={`dashboard-nav-link ${
                            pathname === '/settings/security' ? 'active' : ''
                        }`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path
                                d="M19.5 11.5H5.5C4.39543 11.5 3.5 12.3954 3.5 13.5V20.5C3.5 21.6046 4.39543 22.5 5.5 22.5H19.5C20.6046 22.5 21.5 21.6046 21.5 20.5V13.5C21.5 12.3954 20.6046 11.5 19.5 11.5Z"
                                stroke="#393939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M7.5 11.5V7.5C7.5 6.17392 8.02678 4.90215 8.96447 3.96447C9.90215 3.02678 11.1739 2.5 12.5 2.5C13.8261 2.5 15.0979 3.02678 16.0355 3.96447C16.9732 4.90215 17.5 6.17392 17.5 7.5V11.5"
                                stroke="#393939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Security
                    </Link>
                </AccordionDetails>
            </Accordion>
            <Button className="button-logout" onClick={() => setIsLosgoutOpen(true)}>
                <LogoutOutlinedIcon style={{color: 'F95457'}}/>
                Logout
            </Button>
        </div>
    );
};

export default NavigationLinks;

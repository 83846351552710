import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { publicRequest } from '../../utils/ApiConfig';
import { Alert } from '@mui/material';

const AuthVerifyEmail = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const res = await publicRequest.put(`/admin/verifyEmail/${token}`);
        const status = res.data;
        if (status) {
          setError(false);
          setSuccess(true);
        } else {
          setError(true);
          setSuccess(false); // Ensure success is set to false if status is falsy
        }
      } catch (err) {
        setError(true);
        setSuccess(false); // Ensure success is set to false on error
      }
    };
    verifyToken();
  }, []);

  return (
    <div className="verifyEmailPage">
      <div
        style={{
          margin: '80px auto 80px auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {(error || success) && (
          <Alert
            style={{ maxWidth: '1300px' }}
            severity={`${error ? 'error' : 'success'}`}
          >
            {error ? 'Verification failed' : 'Email Verified successfully'}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default AuthVerifyEmail;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { constants } from '../utils/constants';

export const getCustomers = createAsyncThunk(
  'customers/getAll',
  async ({ searchQuery, page = 1 }) => {
    try {
      const response = await userRequest.get('/admin/customers', {
        params: { page, pageSize: constants.PAGE_SIZE, searchQuery },
      });
      return response.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed getting  customers ');
      }
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async ({ customerId }, { dispatch }) => {
    try {
      await userRequest.delete(`/admin/customers/${customerId}`);
      dispatch(getCustomers());
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed getting  customers ');
      }
    }
  }
);

export const getCustomerDetails = createAsyncThunk(
  'customers/getDetails',
  async (userId) => {
    try {
      const response = await userRequest.get(`/admin/customers/${userId}`);
      const userDocuments = await userRequest.get(
        `/admin/documents/customer/${userId}`
      );
      return { ...response.data, documents: userDocuments.data ?? [] };
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed getting  customers ');
      }
    }
  }
);

export const createNewCustomer = createAsyncThunk(
  'customers/addNew',
  async (customerData) => {
    try {
      const response = await userRequest.post(`/admin/customers`, {
        ...customerData,
      });
      return response.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed create new  customers ');
      }
    }
  }
);

export const updateBulkCustomers = createAsyncThunk(
  'customers/update',
  async ({ ids, updates }, { dispatch }) => {
    try {
      const response = await userRequest.put('/admin/customers/update', {
        ids: ids,
        updates: updates,
      });
      dispatch(getCustomers({ searchQuery: null }));
      return response.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed updating user info ');
      }
    }
  }
);

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customersList: [],
    isLoading: false,
    isUpdating: false,
    error: {
      status: false,
      message: null,
    },
    successMssg: null,
  },
  reducers: {
    resetState: (state) => {
      state.error = {
        status: false,
        message: null,
      };
      state.successMssg = null;
      state.customersList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customersList = action.payload.users;
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.pageSize = action.payload.pageSize;
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Error Getting customers',
        };
      })
      .addCase(getCustomerDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerDetails.fulfilled, (state, action) => {
        state.customerDetails = action.payload;
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getCustomerDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Error Getting customers',
        };
      })
      .addCase(createNewCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreated = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(createNewCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreated = false;
        state.error = {
          status: true,
          message: action.error.message || 'Error Getting customers',
        };
      })
      .addCase(updateBulkCustomers.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateBulkCustomers.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateBulkCustomers.rejected, (state, action) => {
        state.isUpdating = false;
        state.error = {
          status: true,
          message: action.error.message || 'Error Getting customers',
        };
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Error Getting customers',
        };
      });
  },
});
export const { resetState } = customerSlice.actions;
export const customerReducer = customerSlice.reducer;

import React, { useEffect } from 'react';
import Navbar from './Navbar/Navbar';
import { useLocation } from 'react-router-dom';
import Footer from './Footer/Footer';
import './Layout.scss';

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (location.pathname.includes('/auth')) {
    return children;
  }

  return (
    <div className="layout-wrapper">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;

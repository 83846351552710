import React, { useState, useEffect } from 'react';
import {
  Avatar,
  IconButton,
  InputAdornment,
  InputBase,
  Pagination,
} from '@mui/material';
import './Customer.scss';
import CityCheckbox from '../../components/CityCheckbox';
import { useNavigate } from 'react-router-dom';
import CityModal from '../../components/CityModal';
import MobileCustomersTable from '../../components/MobileCustomersTable';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Box, Alert } from '@mui/material';
import {
  deleteCustomer,
  getCustomers,
  updateBulkCustomers,
} from '../../redux/customersSlice';
import CheckIcon from '@mui/icons-material/Check';

const Customers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [checkedCustomers, setCheckedCustomers] = useState([]);
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(1);
  const { totalPages, isLoading, customersList, error, isUpdating } =
    useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getCustomers({ page, searchQuery }));
  }, [dispatch, page]);

  useEffect(() => {
    if (customersList) {
      const formattedList = customersList.map((customer) => ({
        ...customer,
        name:
          customer.firstname || customer.lastname
            ? customer.firstname + ' ' + customer.lastname
            : 'NA',
        telephone: customer.telephone || 'NA',
      }));
      setCustomers(formattedList);
    }
  }, [customersList]);

  const handleCheckCustomer = (event, customer) => {
    if (event.target.checked) {
      setCheckedCustomers((prevState) => [...prevState, customer]);
    } else {
      setCheckedCustomers((prevState) =>
        prevState.filter((presc) => presc._id !== customer._id)
      );
    }
  };

  const selectAllCustomers = (event) => {
    if (event.target.checked) {
      setCheckedCustomers(customers);
    } else setCheckedCustomers([]);
  };

  const handleDeleteCustomer = async () => {
    const updatedCustomers = [...customers];
    updatedCustomers.splice(indexToDelete, 1);
    const targetCustomer = customers[indexToDelete];
    await dispatch(
      deleteCustomer({
        customerId: targetCustomer._id,
      })
    );
    setOpenDeleteCustomerModal(false);
    setCustomers(updatedCustomers);
  };

  // const handleDeleteCheckedCustomers = () => {
  //   const updatedCustomers = customers.filter(
  //     (customer) => !checkedCustomers.includes(customer)
  //   );
  //   setCustomers(updatedCustomers);
  // };

  const handleSearch = () => {
    setPage(1);
    dispatch(getCustomers({ searchQuery }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchQuery(event.target.value);
      handleSearch();
    }
  };

  const handleUpdateCustomers = (isActive) => {
    const ids = checkedCustomers.map((customer) => customer?._id);
    dispatch(updateBulkCustomers({ ids, updates: isActive }));
  };

  return (
    <>
      {error.status && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            bottom: '16px',
            right: '16px',
            zIndex: '50',
          }}
        >
          {error.message}
        </Alert>
      )}
      {isLoading ? (
        <Box
          style={{
            height: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <>
            <CircularProgress color="success" />
            <span>Loading Data...</span>{' '}
          </>
        </Box>
      ) : (
        <>
          <CityModal
            open={openDeleteCustomerModal}
            handleClose={() => setOpenDeleteCustomerModal(false)}
            name="Remove Member"
            content={
              <div className="content-4">
                <div className="top-part">
                  <h1>You are attempting to remove:</h1>
                  <div className="person">
                    <Avatar
                      alt={customers[indexToDelete]?.email}
                      src={
                        customers[indexToDelete]?.profile_img ??
                        '/static/images/avatar/1.jpg'
                      }
                    />
                    <h1>{customers[indexToDelete]?.email}</h1>
                  </div>
                  <p>
                    By removing a family member all their information of
                    prescriptions and medications will be lost.
                  </p>
                </div>
                <div className="buttons-modal">
                  {isLoading ? (
                    <>
                      <CircularProgress
                        color="success"
                        style={{ alignSelf: 'center' }}
                      />
                      <span
                        style={{
                          display: 'block',
                          alignSelf: 'center',
                          color: 'red',
                        }}
                      >
                        Deleting User data please wait...
                      </span>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          handleDeleteCustomer();
                        }}
                        className="red-button"
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => setOpenDeleteCustomerModal(false)}
                        className="white-button"
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            }
          />
          <div className={`customer-wrapper ${isUpdating ? 'disabled' : ''}`}>
            <div className="top-section">
              <h1>Customers</h1>
              <InputBase
                className="mui-register-password"
                type="text"
                defaultValue={searchQuery}
                placeholder="Search For Customers"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={isLoading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleSearch} // Trigger search on icon click
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                          stroke="#616161"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.9996 13.9996L11.0996 11.0996"
                          stroke="#616161"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  </InputAdornment>
                }
              />
              {/* <button className='white-button'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M13.3337 4.5L6.00033 11.8333L2.66699 8.5" stroke="black" strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Multi-select
                                </button> */}
              <button
                onClick={() => navigate('/customers/add-customer')}
                className="green-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M8 3.83203V13.1654"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.33301 8.5H12.6663"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Add Customer
              </button>
            </div>
            {checkedCustomers.length > 0 && (
              <div className="items-selected">
                <div className="left-side-items-selected">
                  <p>{checkedCustomers.length} items selected :</p>
                  <div className="buttons-wrapper-customer">
                    {/* <button
                      className="white-button"
                      onClick={handleDeleteCheckedCustomers}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_3877_51290)">
                          <path
                            d="M2 4.5H14"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.6663 4.5V13.8333C12.6663 14.5 11.9997 15.1667 11.333 15.1667H4.66634C3.99967 15.1667 3.33301 14.5 3.33301 13.8333V4.5"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33301 4.4987V3.16536C5.33301 2.4987 5.99967 1.83203 6.66634 1.83203H9.33301C9.99967 1.83203 10.6663 2.4987 10.6663 3.16536V4.4987"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3877_51290">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Delete
                    </button> */}
                    <button
                      className="white-button"
                      onClick={() => handleUpdateCustomers({ isActive: false })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_3877_46302)">
                          <path
                            d="M7.99967 15.1654C11.6816 15.1654 14.6663 12.1806 14.6663 8.4987C14.6663 4.8168 11.6816 1.83203 7.99967 1.83203C4.31778 1.83203 1.33301 4.8168 1.33301 8.4987C1.33301 12.1806 4.31778 15.1654 7.99967 15.1654Z"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 6.5L6 10.5"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6.5L10 10.5"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3877_46302">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Inactive
                    </button>
                    <button
                      className="white-button"
                      onClick={() => handleUpdateCustomers({ isActive: true })}
                    >
                      <CheckIcon />
                      Active
                    </button>
                  </div>
                </div>
                <IconButton onClick={() => setCheckedCustomers([])}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M18 6.5L6 18.5"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6.5L18 18.5"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconButton>
              </div>
            )}
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="inside-cell">
                      <CityCheckbox
                        onClick={selectAllCustomers}
                        checked={checkedCustomers?.length === customers?.length}
                      />
                      <p>Name</p>
                    </div>
                  </th>
                  <th>E-mail</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {customers.map((customer, index) => {
                const isChecked = checkedCustomers.some(
                  (checkedCustom) => checkedCustom._id === customer._id
                );
                return (
                  <tbody key={customer._id}>
                    <tr className={isChecked ? 'checked' : ''}>
                      <td>
                        <div className="inside-cell">
                          <CityCheckbox
                            checked={isChecked}
                            onClick={(event) =>
                              handleCheckCustomer(event, customer)
                            }
                          />
                          <p>{customer.name}</p>
                        </div>
                      </td>
                      <td>
                        <p>{customer.email}</p>
                      </td>
                      <td>{customer.telephone}</td>
                      <td>
                        <div
                          className={
                            customer?.isActive ? 'green-box2' : 'red-box'
                          }
                        >
                          <p>{customer?.isActive ? 'Active' : 'Inactive'}</p>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <button
                            onClick={() =>
                              navigate(`/customers/history/${customer._id}`)
                            }
                            className="white-button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <path
                                d="M2.5 2.5V5.83333H5.83333"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M2.53333 9.1664C2.681 10.5036 3.2738 11.7524 4.21652 12.7122C5.15924 13.672 6.39718 14.2871 7.73153 14.4588C9.06587 14.6304 10.4192 14.3487 11.5741 13.6587C12.7291 12.9688 13.6187 11.9107 14.1 10.6544C14.5813 9.39808 14.6264 8.01647 14.2281 6.73145C13.8298 5.44642 13.0112 4.33254 11.9037 3.5687C10.7962 2.80485 9.46419 2.43537 8.12148 2.51958C6.77878 2.6038 5.50334 3.13683 4.5 4.03307L2.5 5.83307"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.5 5.16797V8.5013L11.1667 9.83464"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            History
                          </button>
                          <button
                            onClick={() =>
                              navigate(`/customers/details/${customer._id}`)
                            }
                            className="white-button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_3877_49215)">
                                <path
                                  d="M10.167 1.83203H4.50033C4.1467 1.83203 3.80756 1.97251 3.55752 2.22256C3.30747 2.4726 3.16699 2.81174 3.16699 3.16536V13.832C3.16699 14.1857 3.30747 14.5248 3.55752 14.7748C3.80756 15.0249 4.1467 15.1654 4.50033 15.1654H12.5003C12.8539 15.1654 13.1931 15.0249 13.4431 14.7748C13.6932 14.5248 13.8337 14.1857 13.8337 13.832V5.4987L10.167 1.83203Z"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9.83301 1.83203V5.83203H13.833"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3877_49215">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Details
                          </button>
                          <IconButton
                            onClick={() => {
                              setOpenDeleteCustomerModal(true);
                              setIndexToDelete(index);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_3877_42091)">
                                <path
                                  d="M2.5 4.5H14.5"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13.1663 4.5V13.8333C13.1663 14.5 12.4997 15.1667 11.833 15.1667H5.16634C4.49967 15.1667 3.83301 14.5 3.83301 13.8333V4.5"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.83301 4.4987V3.16536C5.83301 2.4987 6.49967 1.83203 7.16634 1.83203H9.83301C10.4997 1.83203 11.1663 2.4987 11.1663 3.16536V4.4987"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3877_42091">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
            {/*Small Display Table*/}
            <MobileCustomersTable
              data={customers}
              checkedCustomers={checkedCustomers}
              handleCheckedCustomer={handleCheckCustomer}
              setOpenDeleteCustomerModal={setOpenDeleteCustomerModal}
              setIndexToDelete={setIndexToDelete}
            />
            <div className="pagination">
              <Pagination
                page={page}
                sx={{ color: '#323232' }}
                value={page}
                onChange={(e, value) => setPage(value)}
                count={totalPages}
                color="primary"
                siblingCount={0}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Customers;

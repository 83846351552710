import React, { useEffect, useState } from 'react';
import './PrescriptionName.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import PatientInformation from './PatientInformation';
import PrescriptionImagesAndNotes from './PrescriptionImagesAndNotes';
import { formatDate } from '../utils/callBack';
import { useDispatch } from 'react-redux';
import { updatePrescriptionById } from '../redux/prescriptionSlice';
import PrintModal from '../components/PrintModal';

const PrescriptionName = ({ prescriptionDetails }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [shouldPrint, setShouldPrintt] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setStatus(prescriptionDetails?.status);
  }, [prescriptionDetails]);

  const handleUpdateStatus = (e) => {
    dispatch(
      updatePrescriptionById({ id, newData: { status: e.target.value } })
    );
  };

  useEffect(() => {
    if (prescriptionDetails?.status) {
      const imagesOne = prescriptionDetails?.attachments?.map((image) => image);
      const nationalId = prescriptionDetails?.documents?.find(
        (doc) => doc?.type === 'nationalId'
      );
      const insurance = prescriptionDetails?.documents?.find(
        (doc) => doc?.type === 'insurance' && doc?.isDefault
      );
      const imageTwo = nationalId?.attatchement_front_url;
      const imageThree = nationalId?.attatchement_back_url;
      const imageFour = insurance?.attatchement_front_url;
      const imageFive = insurance?.attatchement_back_url;
      const imagesArry = [
        ...imagesOne,
        imageTwo,
        imageThree,
        imageFour,
        imageFive,
      ];
      const filteredImages = imagesArry.filter((image) => image !== undefined);
      setImages(filteredImages);
    }
  }, [prescriptionDetails]);

  return (
    <div className="details-wrapper">
      <PrintModal open={printOpen} setOpen={setPrintOpen} images={images} />
      <button onClick={() => navigate('/prescription')} className="back-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.6673 8H3.33398"
            stroke="#219D50"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00065 12.6673L3.33398 8.00065L8.00065 3.33398"
            stroke="#219D50"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Back
      </button>
      <div className="prescription-name">
        <div className="name-left-side">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
          >
            <path
              d="M16.3125 2.16602H6.75C6.15326 2.16602 5.58097 2.39429 5.15901 2.80062C4.73705 3.20695 4.5 3.75805 4.5 4.33268V21.666C4.5 22.2407 4.73705 22.7918 5.15901 23.1981C5.58097 23.6044 6.15326 23.8327 6.75 23.8327H20.25C20.8467 23.8327 21.419 23.6044 21.841 23.1981C22.2629 22.7918 22.5 22.2407 22.5 21.666V8.12435L16.3125 2.16602Z"
              stroke="#1E1E1E"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 2.16602V8.66602H22.5"
              stroke="#1E1E1E"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 14.084H9"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 18.416H9"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.25 9.75H9"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h1>{prescriptionDetails?.name || 'NA'}</h1>
          <Divider
            sx={{ display: { xs: 'none', md: 'flex' } }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <p>Ticket ID: #{id}</p>
          <Divider
            sx={{ display: { xs: 'none', md: 'flex' } }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <p className="lower-p">
            Date: {formatDate(prescriptionDetails?.createdAt)}
          </p>
        </div>
        <div className="name-right-side">
          <button
            className="print-button"
            onClick={() => setPrintOpen(true)}
            disabled={images?.length === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_3830_46721)">
                <path
                  d="M4 6.00065V1.33398H12V6.00065"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.00065 12H2.66732C2.3137 12 1.97456 11.8595 1.72451 11.6095C1.47446 11.3594 1.33398 11.0203 1.33398 10.6667V7.33333C1.33398 6.97971 1.47446 6.64057 1.72451 6.39052C1.97456 6.14048 2.3137 6 2.66732 6H13.334C13.6876 6 14.0267 6.14048 14.2768 6.39052C14.5268 6.64057 14.6673 6.97971 14.6673 7.33333V10.6667C14.6673 11.0203 14.5268 11.3594 14.2768 11.6095C14.0267 11.8595 13.6876 12 13.334 12H12.0007"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 9.33398H4V14.6673H12V9.33398Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3830_46721">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Print
          </button>

          <select
            name="status"
            id="status"
            value={status || 'submitted'}
            onChange={(e) => handleUpdateStatus(e)}
          >
            {!prescriptionDetails?.order?.total && (
              <option value="Submitted">Submitted</option>
            )}
            <option value="Processing">Processing</option>
            {((prescriptionDetails?.order?.total &&
              prescriptionDetails?.status === 'Pending Payment') ||
              !prescriptionDetails?.order?.total) && (
              <option value="Pending Payment">Pending Payment</option>
            )}
            {prescriptionDetails?.order?.method !== 'Deliver' && (
              <option value="Ready For Pickup">Ready For Pickup</option>
            )}
            <option value="Paid">Paid</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
      </div>
      <PatientInformation prescription={prescriptionDetails} />
      <PrescriptionImagesAndNotes prescription={prescriptionDetails} />
    </div>
  );
};

export default PrescriptionName;

import React, {useEffect, useState} from "react";
import "./Navbar.css";
import logo from "../../../assets/City Pharmacy.png";
import {Link} from "react-router-dom";
import {Avatar, Divider, IconButton, InputAdornment, InputBase, useMediaQuery,} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import NavigationLinks from "../../NavigationLinks";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationAlert from "../../NotificationAlert";

const NavBar = () => {
    const Desktop = useMediaQuery("(min-width:1268px)");
    const [showAccountMega, setShowAccountMega] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="Navbar">
            {Desktop ? (
                <div className="nav-wrapper">
                    <div className="upper">
                        <div className='left-nav'>
                            <div className="left">
                                <Link to={"/"}>
                                    <img src={logo} alt="logo" className="logo-img"/>
                                </Link>
                            </div>
                            
                            
                           {/* 
                            <div className="middle">
                                <div className="searchbarwrap">
                                    {/* <input type="text" placeholder="Search among products" /> */}
                                    {/* 
                                    <InputBase
                                        className="mui-register-password"
                                        type="text"
                                        placeholder="Search among products"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton edge="end">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none">
                                                        <path
                                                            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                                                            stroke="#616161"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M13.9996 13.9996L11.0996 11.0996"
                                                            stroke="#616161"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    */} 
                                {/* </div>
                            </div> 
                            */}

                        </div>
                        <NotificationAlert />
                    </div>
                </div>
            ) : (
                <div className="mobileNav">
                    <div className="middle">
                        <Link to={"/"}>
                            <img src={logo} alt="logo"/>
                        </Link>
                    </div>
                    <div className="lower">
                        <div
                            className={`navMenu-avatar-account ${
                                showAccountMega ? 'show' : ''
                            }`}
                            onClick={() => setShowAccountMega(!showAccountMega)}
                        >
                            <div className="main-account-mobile-bttn">
                                <Avatar
                                    alt="Travis Howard"
                                    style={{width: '40px', height: '40px'}}
                                />
                                <div className="right-bttn">
                                    <p className="my-account-drop">My Account</p>
                                    <KeyboardArrowDownIcon/>
                                </div>
                            </div>
                            <NavigationLinks/>
                        </div>
                        <div className='lower-right'>
                            <NotificationAlert />
                            <div style={{display: "flex"}}>
                                <Divider orientation="vertical" variant="middle" flexItem/>
                                <IconButton>
                                    <SearchIcon/>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavBar;

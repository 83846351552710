import React, { useState } from 'react';
import './AddCustomer.scss';
import { MuiTelInput } from 'mui-tel-input';
import { useNavigate } from 'react-router-dom';
import CityModal from '../../components/CityModal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector, useDispatch } from 'react-redux';
import { createNewCustomer } from '../../redux/customersSlice';
import {
  Alert,
  CircularProgress,
  Box,
  IconButton,
  InputAdornment,
  InputBase,
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { checkPasswordStrength } from '../../utils/callBack';
const AddCustomer = () => {
  const navigate = useNavigate();
  const [addMember, setAddMember] = useState(false);
  const [passwordError, setPasswordError] = useState({
    error: false,
    helperText: '',
  });
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const { error, isLoading, isCreated } = useSelector(
    (state) => state.customer
  );
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handlePhoneChange = (value, country) => {
    setData({
      ...data,
      phone: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isStrongPassword = checkPasswordStrength(data.password);
    if (
      data.password === '' ||
      data.confirmPassword === '' ||
      data.password !== data.confirmPassword
    ) {
      setPasswordError({ error: true, helperText: 'Passwords do not match' });
      return;
    }
    if (!isStrongPassword) {
      setPasswordError({
        error: true,
        helperText:
          'password must be at least 8 characters, contains uppercase, lowercase, special character and number',
      });
      return;
    }
    setPasswordError({ error: false, helperText: '' });
    delete data.confirmPassword;
    dispatch(createNewCustomer(data));
    setAddMember(true);
  };

  return (
    <>
      {(error.status || passwordError.error) && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            bottom: '16px',
            right: '16px',
            zIndex: '50',
          }}
        >
          {error.message ?? passwordError.helperText}
        </Alert>
      )}
      {isLoading ? (
        <Box
          style={{
            height: '60vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <>
            <CircularProgress color="success" />
            <span>Loading...</span>{' '}
          </>
        </Box>
      ) : (
        <>
          {!error.status && isCreated && (
            <CityModal
              open={addMember}
              handleClose={() => setAddMember(false)}
              name={''}
              content={
                <div className="content-3">
                  <div className="top-part">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="78"
                      height="79"
                      viewBox="0 0 78 79"
                      fill="none"
                    >
                      <path
                        d="M39 7.99219C21.4957 7.99219 7.3125 22.1754 7.3125 39.6797C7.3125 57.184 21.4957 71.3672 39 71.3672C56.5043 71.3672 70.6875 57.184 70.6875 39.6797C70.6875 22.1754 56.5043 7.99219 39 7.99219ZM34.1098 50.9074C33.7441 51.273 33.2262 51.5777 32.7691 51.5777C32.3121 51.5777 31.7941 51.2578 31.4133 50.8922L22.882 42.3609L25.5938 39.6492L32.7844 46.8398L51.7969 27.6902L54.4629 30.4477L34.1098 50.9074Z"
                        fill="#219D50"
                      />
                    </svg>
                    <h1>Member is Added</h1>
                  </div>
                  <button
                    className="white-button"
                    onClick={() => setAddMember(false)}
                  >
                    Back
                  </button>
                </div>
              }
            />
          )}
          <div className="add-customer-wrapper">
            <button
              className="back-button"
              onClick={() => navigate('/customers')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12.6673 8H3.33398"
                  stroke="#219D50"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.00065 12.6673L3.33398 8.00065L8.00065 3.33398"
                  stroke="#219D50"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </button>
            <h1>Add Customer</h1>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="input-row">
                <input
                  name="firstname"
                  value={data?.firstname}
                  onChange={handleChange}
                  required
                  placeholder="First name"
                />
                <input
                  name="lastname"
                  value={data?.lastname}
                  onChange={handleChange}
                  required
                  placeholder="Last name"
                />
                <input
                  name="email"
                  autoComplete="off"
                  value={data?.email}
                  onChange={handleChange}
                  required
                  placeholder="Email"
                />
              </div>
              {/* <div className="input-row">
                <MuiTelInput
                  onChange={handlePhoneChange}
                  required
                  className="no-border-input"
                  InputLabelProps={{
                    sx: {
                      color: '#616161',
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                  }}
                  InputProps={{
                    sx: {
                      '&:hover fieldset': {
                        border: '1px solid #122146 !important',
                      },
                      '&:focus-within fieldset, &:focus-visible fieldset': {
                        border: '1px solid #122146 !important',
                        boxShadow:
                          '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                      },
                    },
                  }}
                  fullWidth
                  name="phone"
                  defaultCountry="CA"
                  value={data?.phone}
                />
                <select
                  onChange={handleChange}
                  value={data?.status}
                  name="status"
                  required
                  className="input-select"
                  defaultValue={'Active'}
                  disabled={true}
                >
                  <option value="">Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="date_of_birth"
                    label="Date of Birth"
                    className="no-border-input"
                    InputLabelProps={{
                      sx: {
                        color: '#616161',
                        fontWeight: 400,
                        fontSize: '16px',
                      },
                    }}
                    placeholder="Date of Birth"
                  />
                </LocalizationProvider>
              </div> */}
              <div className="input-row">
                <InputBase
                  className={`mui-register-password ${
                    passwordError?.error && 'error'
                  }`}
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                  required
                  type={showPassword.password ? 'text' : 'password'}
                  placeholder="Password *"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            password: !showPassword.password,
                          })
                        }
                        edge="end"
                      >
                        {showPassword.password ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <InputBase
                  className={`mui-register-password ${
                    passwordError?.error && 'error'
                  }`}
                  name="confirmPassword"
                  required
                  onChange={handleChange}
                  value={data.confirmPassword}
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  placeholder="Confirm Password *"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmPassword: !showPassword.confirmPassword,
                          })
                        }
                        edge="end"
                      >
                        {showPassword.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <button className="green-button">Add Customer</button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default AddCustomer;

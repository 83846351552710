import React from 'react';
import LeftSide from '../../components/Login-ForgetPasswordComponentsAdmin/LeftSide';
import Logincomponent from '../../components/Login-ForgetPasswordComponentsAdmin/Login-component';
import './ForgetPassword.scss';

const AdminLogin = () => {
  return (
    <div className="forget-passwordAdmin-wrapper">
      <div className="forget-password">
        <Logincomponent />
        <LeftSide />
      </div>
    </div>
  );
};

export default AdminLogin;

import React from 'react';
import CityCheckbox from "./CityCheckbox";
import {Divider, IconButton} from "@mui/material";
import './MemberSettingsSmallTable.scss'

const MemberSettingsSmallTable = ({data, checkedCustomers, handleCheckedCustomer, handleDeleteMember}) => {
    return (
        <div className='wrapper'>
            {data?.map((customer, index) => {
                const isChecked = checkedCustomers.some(
                    (checkedCustom) => checkedCustom.name === customer.name
                );
                return (
                    <>
                        <div className='inside-wrapper'>
                            <div className='top-name'>
                                <div className='top-name-left'>
                                    <CityCheckbox checked={isChecked}
                                                  onClick={(event) => handleCheckedCustomer(event, customer)}/>
                                    <p>
                                        {customer.name}
                                    </p>
                                    {customer.new &&
                                        <div className='light-green-box'>
                                            New
                                        </div>
                                    }
                                </div>
                                <div
                                    className={customer.status === 'active' ? "green-box2" : "red-box"}>
                                    {customer.status}
                                </div>
                            </div>
                            <div className='bottom'>
                                <div className='bottom-left-side'>
                                    <div style={{display: "flex", alignItems: "center", gap: '4px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                             viewBox="0 0 12 13" fill="none">
                                            <path
                                                d="M9.5 11V10C9.5 9.46957 9.28929 8.96086 8.91421 8.58579C8.53914 8.21071 8.03043 8 7.5 8H4.5C3.96957 8 3.46086 8.21071 3.08579 8.58579C2.71071 8.96086 2.5 9.46957 2.5 10V11"
                                                stroke="#D3D3D3" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M6 6C7.10457 6 8 5.10457 8 4C8 2.89543 7.10457 2 6 2C4.89543 2 4 2.89543 4 4C4 5.10457 4.89543 6 6 6Z"
                                                stroke="#D3D3D3" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <p>{customer.role}</p>
                                        <Divider orientation='vertical' variant='middle'/>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                             viewBox="0 0 12 13" fill="none">
                                            <path
                                                d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z"
                                                stroke="#D3D3D3" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8 1.5V3.5" stroke="#D3D3D3" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M4 1.5V3.5" stroke="#D3D3D3" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M1.5 5.5H10.5" stroke="#D3D3D3" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M4.5 8.5L5.5 9.5L7.5 7.5" stroke="#D3D3D3" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                        <p> {customer.joined_on}</p>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", gap: '4px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                             viewBox="0 0 12 13" fill="none">
                                            <path
                                                d="M10 2.5H2C1.44772 2.5 1 2.94772 1 3.5V9.5C1 10.0523 1.44772 10.5 2 10.5H10C10.5523 10.5 11 10.0523 11 9.5V3.5C11 2.94772 10.5523 2.5 10 2.5Z"
                                                stroke="#D3D3D3" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M11 4L6.515 6.85C6.36064 6.94671 6.18216 6.99801 6 6.99801C5.81784 6.99801 5.63936 6.94671 5.485 6.85L1 4"
                                                stroke="#D3D3D3" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <p>{customer.email}</p>
                                    </div>
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <IconButton>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21"
                                             viewBox="0 0 20 21" fill="none">
                                            <path
                                                d="M9.16602 3.83594H3.33268C2.89065 3.83594 2.46673 4.01153 2.15417 4.32409C1.84161 4.63665 1.66602 5.06058 1.66602 5.5026V17.1693C1.66602 17.6113 1.84161 18.0352 2.15417 18.3478C2.46673 18.6603 2.89065 18.8359 3.33268 18.8359H14.9993C15.4414 18.8359 15.8653 18.6603 16.1779 18.3478C16.4904 18.0352 16.666 17.6113 16.666 17.1693V11.3359"
                                                stroke="#1E1E1E" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path
                                                d="M15.416 2.58417C15.7475 2.25265 16.1972 2.06641 16.666 2.06641C17.1349 2.06641 17.5845 2.25265 17.916 2.58417C18.2475 2.91569 18.4338 3.36533 18.4338 3.83417C18.4338 4.30301 18.2475 4.75265 17.916 5.08417L9.99935 13.0008L6.66602 13.8342L7.49935 10.5008L15.416 2.58417Z"
                                                stroke="#1E1E1E" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </svg>
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteMember(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21"
                                             viewBox="0 0 20 21" fill="none">
                                            <path d="M2.5 5.5H17.5" stroke="#1E1E1E" strokeWidth="1.875"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M15.8327 5.5V17.1667C15.8327 18 14.9994 18.8333 14.166 18.8333H5.83268C4.99935 18.8333 4.16602 18 4.16602 17.1667V5.5"
                                                stroke="#1E1E1E" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path
                                                d="M6.66602 5.4974V3.83073C6.66602 2.9974 7.49935 2.16406 8.33268 2.16406H11.666C12.4993 2.16406 13.3327 2.9974 13.3327 3.83073V5.4974"
                                                stroke="#1E1E1E" strokeWidth="1.875" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M8.33398 9.66406V14.6641" stroke="#1E1E1E" strokeWidth="1.875"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M11.666 9.66406V14.6641" stroke="#1E1E1E" strokeWidth="1.875"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        {(index !== data.length - 1) && <Divider sx={{width: "100%"}}/>}
                    </>
                )
            })}
        </div>
    );
};

export default MemberSettingsSmallTable;
import React from 'react';
import CityCheckbox from './CityCheckbox';
import { formatDate } from '../utils/callBack';

const ArchivePrescriptionTable = ({
  data,
  selectAllPrescriptions,
  checkedPrescriptions,
  handleCheckPrescriptions,
  unarchiveOne,
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <div className="inside-cell">
              <CityCheckbox
                onClick={selectAllPrescriptions}
                checked={
                  checkedPrescriptions?.length === data?.length &&
                  checkedPrescriptions?.length
                }
              />
              <p>Prescription number</p>
            </div>
          </th>
          <th>Refill Date</th>
          <th>Status</th>
          <th>Patient</th>
          <th>Phone Number</th>
          <th>Action</th>
        </tr>
      </thead>
      {data.map((prescription, index) => {
        const isChecked = checkedPrescriptions.some(
          (checkedPresc) => checkedPresc._id === prescription._id
        );
        return (
          <tbody key={index}>
            <tr className={isChecked && 'checked'}>
              <td>
                <div className="inside-cell">
                  <CityCheckbox
                    checked={isChecked}
                    onClick={(event) =>
                      handleCheckPrescriptions(event, prescription)
                    }
                  />
                  <p>
                    {prescription.number ? '#' + prescription.number : 'NA'}
                  </p>
                  {prescription.new && (
                    <div className="green-box">
                      <p className="green">New</p>
                    </div>
                  )}
                </div>
              </td>
              <td>{formatDate(prescription.refill_date) ?? 'NA'}</td>
              <td>
                <div
                  className={
                    prescription.status === 'Processing'
                      ? 'grey-box2'
                      : prescription.status === 'Paid' ||
                        prescription.status === 'Delivered'
                      ? 'green-box2'
                      : prescription.status === 'Cancelled'
                      ? 'red-box'
                      : 'light-green-box'
                  }
                >
                  <p>{prescription.status ?? 'NA'}</p>
                </div>
              </td>
              <td>
                {prescription?.patient_fname || prescription?.patient_lname
                  ? prescription?.patient_fname +
                    ' ' +
                    prescription?.patient_lname
                  : 'NA'}
              </td>
              <td>{prescription?.phone ?? 'NA'}</td>
              <td>
                <div className="inside-cell">
                  <button
                    onClick={() => unarchiveOne(prescription)}
                    className="button-unarchive"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M10 9.33268L13.3333 5.99935L10 2.66602"
                        stroke="#1E1E1E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.3337 6H6.33366C5.3612 6 4.42857 6.38631 3.74093 7.07394C3.0533 7.76157 2.66699 8.69421 2.66699 9.66667C2.66699 10.1482 2.76183 10.625 2.9461 11.0698C3.13037 11.5147 3.40045 11.9189 3.74093 12.2594C4.42857 12.947 5.3612 13.3333 6.33366 13.3333H8.66699"
                        stroke="#1E1E1E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Unarchive
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        );
      })}
    </table>
  );
};

export default ArchivePrescriptionTable;

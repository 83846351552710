import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Prescription from './pages/Prescription/Prescription';
import PrescriptionDetails from './pages/PrescriptionDetails/PrescriptionDetails';
import Customers from './pages/Customers/Customers';
import DashboardLayout from './components/DashboardLayout/DashboardLayout';
import AddCustomer from './pages/AddCustomer/AddCustomer';
import CustomerDetails from './pages/CustomerDetails/CustomerDetails';
import CustomerHistory from './pages/CustomerHistory/CustomerHistory';
import General from './pages/Settings/General';
import MemberSettings from './pages/Settings/MemberSettings';
import AddMember from './pages/Settings/AddMember';
import Security from './pages/Settings/Security';
import AdminLogin from './pages/Auth/Login';
import ChangePassword from './pages/Auth/ChangePassword';
import AuthRequired from './utils/authRequired';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from './socket';
import { useEffect } from 'react';
import AuthVerifyEmail from './pages/VerifyEmail/VerifyEmail';

function App() {
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (user) {
      socket.connect();
      socket.emit('userConnected', user._id);
    } else {
      socket.disconnect();
    }
  }, [user]);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/auth">
            <Route path="login" element={<AdminLogin />} />
            <Route path="resetpassword" element={<ChangePassword />} />
          </Route>
          <Route path="/" element={<Navigate to="/prescription" replace />} />
          <Route path="/auth/verify/:token" element={<AuthVerifyEmail />} />
          <Route
            element={
              <AuthRequired>
                <DashboardLayout />
              </AuthRequired>
            }
          >
            <Route path="/prescription">
              <Route index element={<Prescription />} />
              <Route path=":id" element={<PrescriptionDetails />} />
            </Route>

            <Route path="/customers">
              <Route index element={<Customers />} />
              <Route path="add-customer" element={<AddCustomer />} />
              <Route path="details/:id" element={<CustomerDetails />} />
              <Route path="history/:id" element={<CustomerHistory />} />
            </Route>
            <Route path="/settings">
              <Route path="general" element={<General />} />
              <Route path="member-settings">
                <Route index element={<MemberSettings />} />
                <Route path="add-member" element={<AddMember />} />
              </Route>
              <Route path="security" element={<Security />} />
            </Route>
          </Route>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import './RightSide.scss';
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../redux/userSlice';
import { useLocation } from 'react-router-dom';


const Logincomponent = () => {

  const dispatch = useDispatch();
  const [mode, setMode] = useState('login');
  const [login, setLogin] = useState({});
  const [signup, setSignUp] = useState({});
  const { currentUser, loginError, isLoading, isRegistered } = useSelector((state) => state?.user ?? {});
  const [isDisabled, setIsDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeLogin = (event) => {
    const { name, value } = event.target;
    if (name === 'remember_me') {
      setLogin({ ...login, remember_me: !login.remember_me });
    } else {
      setLogin({ ...login, [name]: value });
    }
  };

  const validate = () => {
    if (signup.password) {
      if (
        signup.email &&
        signup.firstname &&
        signup.lastname &&
        signup.password === signup.confirm_password
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (login.email) {
        if (login.password) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      }
    }
  };

  useEffect(() => {
    validate();
  }, [signup, login]);

  const handleLogin = async () => {
    dispatch(userLogin(login))
    setLogin(login)
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/prescription');
    }
    if (isRegistered) {
      setTimeout(() => {
        setMode('login');
      }, [2000]);
    }
  }, [currentUser, isRegistered]);

  return (
    <div className="AdminLogin-wrapper">
      <div className="form-wrapper">
        <h1>Welcome Back!</h1>
        <div className="form-row">
          <input
            name="email"
            onChange={handleChangeLogin}
            placeholder="Email Address*"
            type="email"
          />
        </div>
        <div className="form-row">
          <InputBase
            className="mui-register-password"
            onChange={handleChangeLogin}
            name="password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {/* <Link to="/forget-passwordAdmin" className="forgot-password">
          <p>Forget your password?</p>
        </Link> */}
        <div className="checkbox">
          <input
            name="remember_me"
            onChange={handleChangeLogin}
            checked={login.remember_me}
            type="checkbox"
          />
          <p>Remember me</p>
        </div>
        {loginError?.status && (
          <Alert severity="error">{loginError.message}</Alert>
        )}
        <div className="buttons">
          <button onClick={handleLogin}
            disabled={isDisabled || isLoading}

          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logincomponent;

import React, { useState } from 'react';
import './CustomerDetails.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PatientInformationPaid from '../../components/PatientInformationPaid';
import { useEffect } from 'react';
import { deleteCustomer, getCustomerDetails } from '../../redux/customersSlice';
import { CircularProgress, Box } from '@mui/material';
import CityModal from '../../components/CityModal';
import { Avatar } from '@mui/material';

const CustomerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);

  useEffect(() => {
    dispatch(getCustomerDetails(id));
  }, []);

  const { customerDetails, isLoading } = useSelector((state) => state.customer);

  const handleDeleteCustomer = async () => {
    await dispatch(
      deleteCustomer({
        customerId: customerDetails?._id,
      })
    );
    setOpenDeleteCustomerModal(false);
    navigate('/customers');
  };

  return (
    <div className="customer-details-wrapper">
      <CityModal
        open={openDeleteCustomerModal}
        handleClose={() => setOpenDeleteCustomerModal(false)}
        name="Remove Member"
        content={
          <div className="content-4">
            <div className="top-part">
              <h1>You are attempting to remove:</h1>
              <div className="person">
                <Avatar
                  alt={customerDetails?.firstname}
                  src={
                    customerDetails?.profile_img ??
                    '/static/images/avatar/1.jpg'
                  }
                />
                <h1>{customerDetails?.firstname}</h1>
              </div>
              <p>
                By removing a family member all their information of
                prescriptions and medications will be lost.
              </p>
            </div>
            <div className="buttons-modal">
              {isLoading ? (
                <>
                  <CircularProgress
                    color="success"
                    style={{ alignSelf: 'center' }}
                  />
                  <span
                    style={{
                      display: 'block',
                      alignSelf: 'center',
                      color: 'red',
                    }}
                  >
                    Deleting User data please wait...
                  </span>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      handleDeleteCustomer();
                    }}
                    className="red-button"
                  >
                    Remove
                  </button>
                  <button
                    onClick={() => setOpenDeleteCustomerModal(false)}
                    className="white-button"
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        }
      />
      <button className="back-button" onClick={() => navigate('/customers')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.6673 8H3.33398"
            stroke="#219D50"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00065 12.6673L3.33398 8.00065L8.00065 3.33398"
            stroke="#219D50"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Back
      </button>
      {isLoading ? (
        <Box
          style={{
            height: '60vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <>
            <CircularProgress color="success" />
            <span>Loading Data...</span>{' '}
          </>
        </Box>
      ) : (
        <>
          <div className="top-part">
            <div className="left-side-customer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
              >
                <path
                  d="M15.709 2.66797H6.50065C5.92602 2.66797 5.37491 2.89624 4.96859 3.30257C4.56226 3.7089 4.33398 4.26 4.33398 4.83464V22.168C4.33398 22.7426 4.56226 23.2937 4.96859 23.7C5.37491 24.1064 5.92602 24.3346 6.50065 24.3346H19.5006C20.0753 24.3346 20.6264 24.1064 21.0327 23.7C21.439 23.2937 21.6673 22.7426 21.6673 22.168V8.6263L15.709 2.66797Z"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.166 2.66797V9.16797H21.666"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Details</p>
            </div>
            <button
              className="white-button"
              onClick={() => navigate(`/customers/history/${id}`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M2 2.5V5.83333H5.33333"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.03333 9.1664C2.181 10.5036 2.7738 11.7524 3.71652 12.7122C4.65924 13.672 5.89718 14.2871 7.23153 14.4588C8.56587 14.6304 9.91919 14.3487 11.0741 13.6587C12.2291 12.9688 13.1187 11.9107 13.6 10.6544C14.0813 9.39808 14.1264 8.01647 13.7281 6.73145C13.3298 5.44642 12.5112 4.33254 11.4037 3.5687C10.2962 2.80485 8.96419 2.43537 7.62148 2.51958C6.27878 2.6038 5.00334 3.13683 4 4.03307L2 5.83307"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 5.16797V8.5013L10.6667 9.83464"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              History
            </button>
            <button
              className="red-button"
              onClick={() => setOpenDeleteCustomerModal(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <g clipPath="url(#clip0_3899_47479)">
                  <path
                    d="M2 4.5H14"
                    stroke="#CD1D1D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.6673 4.5V13.8333C12.6673 14.5 12.0007 15.1667 11.334 15.1667H4.66732C4.00065 15.1667 3.33398 14.5 3.33398 13.8333V4.5"
                    stroke="#CD1D1D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33398 4.4987V3.16536C5.33398 2.4987 6.00065 1.83203 6.66732 1.83203H9.33398C10.0007 1.83203 10.6673 2.4987 10.6673 3.16536V4.4987"
                    stroke="#CD1D1D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3899_47479">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Delete
            </button>
          </div>
          <PatientInformationPaid data={customerDetails} />
        </>
      )}
    </div>
  );
};

export default CustomerDetails;

import React, { useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  InputBase,
  Pagination,
  useMediaQuery,
} from '@mui/material';
import './PrescriptionTable.scss';
import AllPrescriptionTable from './AllPrescriptionTable';
import ArchivePrescriptionTable from './ArchivePrescriptionTable';
import MobilePrescriptionTable from './MobilePrescriptionTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPrescriptions,
  updateBulkPrescriptions,
  updatePrescriptionById,
} from '../redux/prescriptionSlice';
import { CircularProgress, Box } from '@mui/material';

const PrescriptionTable = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:1200px)');
  const [categories, setCategories] = useState([
    { name: 'All', amount: 0 },
    { name: 'Fill', amount: 0 },
    { name: 'Refill', amount: 0 },
    { name: 'Transfer', amount: 0 },
    { name: 'Archive', amount: 0 },
  ]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [archiveUpdated, setArchiveUpdated] = useState(false);
  const [checkedPrescriptions, setCheckedPrescriptions] = useState([]);
  const { prescriptionsList, typesCount, isLoading, totalPages, error } =
    useSelector((state) => state.prescription) ?? [];
  const [status, setStatus] = useState('All');
  const [timeFrame, setTimeFrame] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getPrescriptions({
        filters: {
          status,
          timeFrame,
          searchQuery,
          type: selectedCategory ?? 'All',
        },
        page,
      })
    );
  }, [status, timeFrame, page, selectedCategory, archiveUpdated]);

  useEffect(() => {
    setCategories(
      categories.map((category) => {
        const count =
          typesCount?.find(({ type }) => type == category?.name)?.count ?? 0;
        category.amount = count;
        return category;
      })
    );
  }, [prescriptionsList, selectedCategory]);

  const selectCategory = (categoryName) => {
    setCheckedPrescriptions([]);
    setSelectedCategory(categoryName);
  };

  const handleCheckPrescription = (event, prescription) => {
    if (event.target.checked) {
      setCheckedPrescriptions((prevState) => [...prevState, prescription]);
    } else {
      setCheckedPrescriptions((prevState) =>
        prevState.filter((presc) => presc._id !== prescription._id)
      );
    }
  };

  const handleSearch = () => {
    setPage(1);
    dispatch(
      getPrescriptions({ filters: { status, timeFrame, searchQuery }, page })
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchQuery(event.target.value);
      handleSearch();
    }
  };

  const selectAllPrescriptions = (event) => {
    if (event.target.checked) {
      setCheckedPrescriptions(prescriptionsList);
    } else {
      setCheckedPrescriptions([]);
    }
  };

  const archive = () => {
    const ids = checkedPrescriptions.map((prescription) => prescription._id);
    dispatch(updateBulkPrescriptions({ ids, updates: { isArchived: true } }));
    setArchiveUpdated(true);
    setCheckedPrescriptions([]);
  };

  const unarchive = () => {
    const ids = checkedPrescriptions.map((prescription) => prescription._id);
    dispatch(updateBulkPrescriptions({ ids, updates: { isArchived: false } }));
    setArchiveUpdated(true);
    setCheckedPrescriptions([]);
  };

  const unarchiveOne = (prescription) => {
    dispatch(
      updatePrescriptionById({
        id: prescription._id,
        newData: { isArchived: false },
      })
    );
  };

  return (
    <div className="prescription-table-wrapper">
      <div className="top-search">
        <div className="text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
          >
            <path
              d="M16.3125 2.16602H6.75C6.15326 2.16602 5.58097 2.39429 5.15901 2.80062C4.73705 3.20695 4.5 3.75805 4.5 4.33268V21.666C4.5 22.2407 4.73705 22.7918 5.15901 23.1981C5.58097 23.6044 6.15326 23.8327 6.75 23.8327H20.25C20.8467 23.8327 21.419 23.6044 21.841 23.1981C22.2629 22.7918 22.5 22.2407 22.5 21.666V8.12435L16.3125 2.16602Z"
              stroke="#1E1E1E"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 2.16602V8.66602H22.5"
              stroke="#1E1E1E"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 14.084H9"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 18.416H9"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.25 9.75H9"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>Prescriptions</p>
        </div>
        <InputBase
          className="mui-register-password"
          type="text"
          defaultValue={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          placeholder="Search in prescriptions"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                    stroke="#616161"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.9996 13.9996L11.0996 11.0996"
                    stroke="#616161"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div className="filter-section">
        <div className="left-side-filter">
          {categories.map((categoryToMap, index) => (
            <p
              className={
                categoryToMap.name === selectedCategory ? 'selected' : ''
              }
              key={index}
              onClick={() => selectCategory(categoryToMap.name)}
            >
              {categoryToMap.name} ({categoryToMap.amount})
            </p>
          ))}
        </div>
        <div className="right-side-filter">
          <label>Status</label>
          <select
            name="status"
            id="status"
            onChange={(event) => setStatus(event.target.value)}
          >
            <option value="All">All</option>
            <option value="Paid">Paid</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
            <option value="Processing">Processing</option>
            <option value="Cancelled">Cancelled</option>
          </select>
          <label>Time Frame</label>
          <select
            name="time"
            id="time"
            onChange={(event) => setTimeFrame(event.target.value)}
          >
            <option value="All">All </option>
            <option value="month">This Month</option>
            <option value="week">This Week</option>
            <option value="day">Today</option>
          </select>
          {/* <div className='multi-select'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.3337 4L6.00033 11.3333L2.66699 8" stroke="black" strokeWidth="2"
                                strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p>Multi-select</p>
                    </div> */}
        </div>
      </div>
      {isLoading && (
        <Box
          style={{
            height: '60vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <>
            <CircularProgress color="success" />
            <span>Loading Data...</span>{' '}
          </>
        </Box>
      )}

      {!isLoading && checkedPrescriptions.length > 0 && (
        <div className="items-selected">
          <div className="left-side-items-selected">
            <p>{checkedPrescriptions.length} items selected :</p>
            {selectedCategory !== 'Archive' ? (
              <button onClick={archive}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8H10.6663L9.33301 10H6.66634L5.33301 8H1.33301"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.63301 3.40602L1.33301 7.99935V11.9993C1.33301 12.353 1.47348 12.6921 1.72353 12.9422C1.97358 13.1922 2.31272 13.3327 2.66634 13.3327H13.333C13.6866 13.3327 14.0258 13.1922 14.2758 12.9422C14.5259 12.6921 14.6663 12.353 14.6663 11.9993V7.99935L12.3663 3.40602C12.256 3.18387 12.0858 2.99693 11.875 2.8662C11.6642 2.73547 11.4211 2.66615 11.173 2.66602H4.82634C4.57829 2.66615 4.33518 2.73547 4.12437 2.8662C3.91356 2.99693 3.74339 3.18387 3.63301 3.40602Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Archive
              </button>
            ) : (
              <button onClick={unarchive}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M10 9.33268L13.3333 5.99935L10 2.66602"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.3337 6H6.33366C5.3612 6 4.42857 6.38631 3.74093 7.07394C3.0533 7.76157 2.66699 8.69421 2.66699 9.66667C2.66699 10.1482 2.76183 10.625 2.9461 11.0698C3.13037 11.5147 3.40045 11.9189 3.74093 12.2594C4.42857 12.947 5.3612 13.3333 6.33366 13.3333H8.66699"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Unarchive
              </button>
            )}
          </div>
          <IconButton onClick={() => setCheckedPrescriptions([])}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
      )}
      {!isLoading && selectedCategory !== 'Archive' ? (
        !matches ? (
          <AllPrescriptionTable
            data={prescriptionsList}
            checkedPrescriptions={checkedPrescriptions}
            handleCheckPrescriptions={handleCheckPrescription}
            selectAllPrescriptions={selectAllPrescriptions}
          />
        ) : (
          <MobilePrescriptionTable
            data={prescriptionsList}
            checkedPrescriptions={checkedPrescriptions}
            handleCheckPrescriptions={handleCheckPrescription}
          />
        )
      ) : null}

      {!isLoading && selectedCategory === 'Archive' ? (
        !matches ? (
          <ArchivePrescriptionTable
            data={prescriptionsList}
            checkedPrescriptions={checkedPrescriptions}
            handleCheckPrescriptions={handleCheckPrescription}
            selectAllPrescriptions={selectAllPrescriptions}
            unarchiveOne={unarchiveOne}
          />
        ) : (
          <MobilePrescriptionTable
            data={prescriptionsList}
            checkedPrescriptions={checkedPrescriptions}
            handleCheckPrescriptions={handleCheckPrescription}
            unarchiveOne={unarchiveOne}
            isArchive={true}
          />
        )
      ) : null}

      {!isLoading && (
        <div className="pagination">
          <Pagination
            page={page}
            sx={{ color: '#323232' }}
            value={page}
            onChange={(e, value) => setPage(value)}
            count={totalPages}
            color="primary"
            siblingCount={0}
          />
        </div>
      )}
    </div>
  );
};

export default PrescriptionTable;

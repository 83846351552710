import React from 'react';
import { Avatar, Divider, IconButton, Modal } from "@mui/material";
import './SendPrescriptionModal.scss'
import { useSelector, useDispatch } from "react-redux";

const SendPrescriptionModal = ({ open, handleClose, handleSendPrescription }) => {
    const { prescriptionDetails } = useSelector(state => state.prescription);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal'>
                <div className='top-part'>
                    <h1>Confirm Prescription Submission</h1>
                    <IconButton onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                            <path d="M27.9961 13L11.9961 29.0005" stroke="#979797" strokeWidth="1.28573"
                                strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.9961 13L27.9961 29.0005" stroke="#979797" strokeWidth="1.28573"
                                strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                </div>
                <div className='context'>
                    <h1>You are attempting to submit:</h1>
                    <div className='green-box-modal'>
                        <h1>{prescriptionDetails?.name}</h1>
                        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            <p>{prescriptionDetails?.patient_fname || prescriptionDetails?.patient_lname ? prescriptionDetails?.patient_fname + " " + prescriptionDetails?.patient_lname : 'NA'}</p>
                        </div>
                    </div>
                    <p>By proceeding, this prescription will be sent to your patient for payment.</p>
                    <Divider sx={{ width: "100%" }} />
                    <p>Are you sure you want to proceed?</p>
                    <div className='buttons'>
                        <button onClick={handleSendPrescription}>Yes, Continue</button>
                        <button className='light-green'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"
                                fill="none">
                                <g clipPath="url(#clip0_3860_57119)">
                                    <path
                                        d="M13.834 2.66602H3.16732C2.43094 2.66602 1.83398 3.26297 1.83398 3.99935V4.66602C1.83398 5.4024 2.43094 5.99935 3.16732 5.99935H13.834C14.5704 5.99935 15.1673 5.4024 15.1673 4.66602V3.99935C15.1673 3.26297 14.5704 2.66602 13.834 2.66602Z"
                                        stroke="#219D50" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path
                                        d="M3.16602 6V12C3.16602 12.3536 3.30649 12.6928 3.55654 12.9428C3.80659 13.1929 4.14573 13.3333 4.49935 13.3333H12.4993C12.853 13.3333 13.1921 13.1929 13.4422 12.9428C13.6922 12.6928 13.8327 12.3536 13.8327 12V6"
                                        stroke="#219D50" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M7.16602 8.66602H9.83268" stroke="#219D50" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3860_57119">
                                        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Archive
                        </button>
                        <button onClick={handleClose} className='white-button'>
                            No, need adjustment
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SendPrescriptionModal;
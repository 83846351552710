import React from 'react';
import './Prescription.scss'
import PrescriptionStats from "../../components/PrescriptionStats";
import PrescriptionTable from "../../components/PrescriptionTable";
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';

const Prescription = () => {
    const { error } = useSelector(state => state.prescription)
    return (
        <>
            {(error.status) && (
                <Alert
                    severity='error'
                    style={{
                        position: 'fixed',
                        bottom: '16px',
                        right: '16px',
                        zIndex: '50',
                    }}
                >
                    {error?.message}
                </Alert>
            )}

            <PrescriptionStats />
            <PrescriptionTable />
        </>
    );
};

export default Prescription;
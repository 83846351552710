import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { constants } from '../utils/constants';

export const getPrescriptions = createAsyncThunk(
  'prescriptions/getAll',
  async ({ filters = {}, page = 1 }) => {
    try {
      const response = await userRequest.get('/admin/prescription', {
        params: { page, pageSize: constants.PAGE_SIZE, ...filters },
      });
      return response.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed getting prescriptions ');
      }
    }
  }
);

export const getPrescriptionById = createAsyncThunk(
  'prescriptions/getOneById',
  async (id) => {
    try {
      const prescription = await userRequest.get(`/admin/prescription/${id}`);
      const { user_id } = prescription.data;
      const customerDetails = await userRequest.get(
        `/admin/customers/${user_id}`
      );
      const customerDocuments = await userRequest.get(
        `/admin/documents/customer/${user_id}`
      );
      const prescriptionDetails = {
        ...prescription.data,
        documents: customerDocuments.data ?? [],
        customerDetails: customerDetails.data,
      };
      return prescriptionDetails;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed getting  prescriptions ');
      }
    }
  }
);

export const getPrescriptionByCustomerId = createAsyncThunk(
  'prescriptions/getOneByCustomerId',
  async (customerId) => {
    try {
      const prescription = await userRequest.get(
        `/admin/${customerId}/prescription`
      );
      return prescription.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed getting  prescriptions of customer');
      }
    }
  }
);

export const updatePrescriptionById = createAsyncThunk(
  'prescriptions/updateOneById',
  async ({ id, newData }) => {
    try {
      const prescription = await userRequest.put(
        `/admin/prescription/${id}`,
        newData
      );
      return prescription.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed update  prescription ');
      }
    }
  }
);

export const addBasketItem = createAsyncThunk(
  'prescriptions/addBasketItem',
  async ({ id, basketItem }) => {
    try {
      const prescription = await userRequest.post(
        `/admin/prescription/${id}/basketitem/`,
        basketItem
      );
      return prescription.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed add  basket item ');
      }
    }
  }
);

export const updateBasketItem = createAsyncThunk(
  'prescriptions/updateBasketItem',
  async ({ prescriptionId, basketItem }) => {
    try {
      console.log(prescriptionId);
      const prescription = await userRequest.put(
        `prescription/${prescriptionId}/basketitem`,
        basketItem
      );
      return prescription.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed update  basket item ');
      }
    }
  }
);

export const createPrescriptionOrder = createAsyncThunk(
  'prescriptions/createPrescriptionOrder',
  async ({ prescriptionId, tax = 0 }) => {
    try {
      const prescription = await userRequest.post(
        `/prescription/${prescriptionId}/order`,
        { tax_amount: tax }
      );
      return prescription.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed create  prescription order');
      }
    }
  }
);

export const deleteBasketItem = createAsyncThunk(
  'prescriptions/deleteBasketItem',
  async ({ prescriptionId, basketItemId }) => {
    try {
      const prescription = await userRequest.delete(
        `admin/prescription/${prescriptionId}/basketitem/${basketItemId}`
      );
      return prescription.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed delete basket item ');
      }
    }
  }
);

export const getPrescriptionOrdersStats = createAsyncThunk(
  'prescriptions/order/stats',
  async () => {
    try {
      const stats = await userRequest.get(`/admin/prescription/order/stats`);
      return stats.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed get prescription order stats');
      }
    }
  }
);

export const getAllPrescriptionCount = createAsyncThunk(
  'prescriptions/allCount',
  async () => {
    try {
      const result = await userRequest.get(`/admin/prescription/count`);
      return result.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed get prescription count');
      }
    }
  }
);

export const updateBulkPrescriptions = createAsyncThunk(
  'prescriptions/bulk-update',
  async ({ ids, updates }) => {
    try {
      const result = await userRequest.post(`/admin/prescription/bulk-update`, {
        ids,
        updates,
      });
      return result.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed get prescription order stats');
      }
    }
  }
);

const prescriptionSlice = createSlice({
  name: 'prescription',
  initialState: {
    prescriptionsList: [],
    isLoading: false,
    isUpdating: false,
    stats: [],
    error: {
      status: false,
      message: null,
    },
    successMssg: null,
  },
  reducers: {
    resetState: (state) => {
      state.error = {
        status: false,
        message: null,
      };
      state.successMssg = null;
      state.prescriptionsList = [];
    },
    addTax: (state, action) => {
      const { tax } = action.payload;
      if (state.prescriptionDetails) {
        state.tax = tax;
        state.isLoading = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrescriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrescriptions.fulfilled, (state, action) => {
        state.prescriptionsList = action.payload?.prescriptions ?? [];
        state.typesCount = action.payload.typesCount;
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.pageSize = action.payload.pageSize;
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getPrescriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Error Getting prescriptions',
        };
      })
      .addCase(getPrescriptionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrescriptionById.fulfilled, (state, action) => {
        state.prescriptionDetails = action.payload;
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getPrescriptionById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error Getting prescription Details ',
        };
      })
      .addCase(getPrescriptionByCustomerId.pending, (state) => {
        state.isLoading = true;
        state.customerPrescriptions = [];
      })
      .addCase(getPrescriptionByCustomerId.fulfilled, (state, action) => {
        state.customerPrescriptions = action.payload;
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getPrescriptionByCustomerId.rejected, (state, action) => {
        state.isLoading = false;
        state.customerPrescriptions = [];
        state.error = {
          status: true,
          message:
            action.error.message || 'Error Getting prescription Details ',
        };
      })

      .addCase(updatePrescriptionById.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updatePrescriptionById.fulfilled, (state, action) => {
        state.prescriptionDetails = {
          ...state.prescriptionDetails,
          ...action.payload,
        };
        state.isUpdating = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updatePrescriptionById.rejected, (state, action) => {
        state.isUpdating = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error updating  prescription Details ',
        };
      })
      .addCase(addBasketItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBasketItem.fulfilled, (state, action) => {
        state.prescriptionDetails = {
          ...state.prescriptionDetails,
          ...action.payload,
        };
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(addBasketItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error add basket items  to prescription ',
        };
      })
      .addCase(updateBasketItem.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateBasketItem.fulfilled, (state, action) => {
        state.prescriptionDetails = {
          ...state.prescriptionDetails,
          ...action.payload,
        };
        state.isUpdating = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateBasketItem.rejected, (state, action) => {
        state.isUpdating = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error add basket items  to prescription ',
        };
      })
      .addCase(deleteBasketItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBasketItem.fulfilled, (state, action) => {
        state.prescriptionDetails = {
          ...state.prescriptionDetails,
          ...action.payload,
        };
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(deleteBasketItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error add basket items  to prescription ',
        };
      })

      .addCase(createPrescriptionOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPrescriptionOrder.fulfilled, (state, action) => {
        state.prescriptionDetails = {
          ...state.prescriptionDetails,
          order: action.payload,
        };
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(createPrescriptionOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error add basket items  to prescription ',
        };
      })
      .addCase(updateBulkPrescriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBulkPrescriptions.fulfilled, (state, action) => {
        state.prescriptionDetails = {
          ...state.prescriptionDetails,
          order: action.payload,
        };
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateBulkPrescriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error add basket items  to prescription ',
        };
      })
      .addCase(getPrescriptionOrdersStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrescriptionOrdersStats.fulfilled, (state, action) => {
        state.stats = action.payload;
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getPrescriptionOrdersStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error get prescription order stats ',
        };
      })
      .addCase(getAllPrescriptionCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPrescriptionCount.fulfilled, (state, action) => {
        state.allPrescriptionsCount = action.payload.count ?? 0;
        state.isLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getAllPrescriptionCount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message:
            action.error.message || 'Error get prescription order stats ',
        };
      });
  },
});
export const { resetState, addTax } = prescriptionSlice.actions;
export const prescriptionReducer = prescriptionSlice.reducer;

import React, { useEffect, useRef, useState } from 'react';
import './PrescriptionImagesAndNotes.scss';
import { Avatar, IconButton, InputAdornment, InputBase } from '@mui/material';
import { socket } from '../socket.js';
import {
  getChatByPrescId,
  sendNewMessage,
  updateMessage,
} from '../redux/ChatSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../utils/callBack.js';

const PrescriptionImagesAndNotes = ({ prescription }) => {
  const scrollableChatRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.currentUser);
  const chat = useSelector((state) => state.chat?.conversation);
  const [message, setMessage] = useState('');

  // fetch chat by prescId
  useEffect(() => {
    if (prescription) {
      dispatch(getChatByPrescId(prescription?._id));
    }
  }, [prescription]);
  const handleRecieveMssg = (msg) => {
    if (msg?.chat_id === chat?._id) {
      dispatch(updateMessage(msg));
    }
  };
  // handles recieved Message from socket
  socket.on('recieve message', handleRecieveMssg);
  const handleChangeMessage = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const isMe = (message) => {
    return message?.sender?._id === user._id || message?.sender === user._id;
  };

  // Scroll to the bottom when messages change
  useEffect(() => {
    scrollableChatRef?.current?.scrollTo({
      top: scrollableChatRef?.current?.scrollHeight,
      behavior: 'smooth',
    });
  }, [chat]);

  const submitMessage = () => {
    dispatch(
      sendNewMessage({
        userId: user?._id,
        chatId: chat?._id,
        content: message,
        recieverId: chat.user_id,
      })
    );
    setMessage('');
  };
  return (
    <div className="prescription-images-wrapper">
      <div className="left-side-image">
        <h1>
          {prescription?.doctor_fname
            ? 'Doctors Information'
            : prescription?.curr_pharmacy_name
            ? 'Pharmacy Information'
            : prescription?.attachments && prescription?.attachments?.length > 0
            ? 'Prescription Images'
            : 'Prescription Info'}
        </h1>
        <div className="image-section">
          {prescription.doctor_fname ? (
            <div className="doctor-info">
              <span className="name">Doctor’s Name</span>
              <div className="doctor-name-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_4602_62347)">
                    <path
                      d="M13.8333 6.06641H7.83333C7.09695 6.06641 6.5 6.66336 6.5 7.39974V13.3997C6.5 14.1361 7.09695 14.7331 7.83333 14.7331H13.8333C14.5697 14.7331 15.1667 14.1361 15.1667 13.3997V7.39974C15.1667 6.66336 14.5697 6.06641 13.8333 6.06641Z"
                      stroke="#219D50"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.83398 10.0651H3.16732C2.8137 10.0651 2.47456 9.92463 2.22451 9.67458C1.97446 9.42453 1.83398 9.08539 1.83398 8.73177V2.73177C1.83398 2.37815 1.97446 2.03901 2.22451 1.78896C2.47456 1.53891 2.8137 1.39844 3.16732 1.39844H9.16732C9.52094 1.39844 9.86008 1.53891 10.1101 1.78896C10.3602 2.03901 10.5007 2.37815 10.5007 2.73177V3.39844"
                      stroke="#219D50"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4602_62347">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5 0.0664062)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p>
                  {prescription.doctor_fname} {prescription.doctor_lname}
                </p>
              </div>
            </div>
          ) : prescription?.curr_pharmacy_name ? (
            <>
              <div className="doctor-info">
                <span className="name">Current Pharmacy</span>
                <div className="doctor-name-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_4602_62347)">
                      <path
                        d="M13.8333 6.06641H7.83333C7.09695 6.06641 6.5 6.66336 6.5 7.39974V13.3997C6.5 14.1361 7.09695 14.7331 7.83333 14.7331H13.8333C14.5697 14.7331 15.1667 14.1361 15.1667 13.3997V7.39974C15.1667 6.66336 14.5697 6.06641 13.8333 6.06641Z"
                        stroke="#219D50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.83398 10.0651H3.16732C2.8137 10.0651 2.47456 9.92463 2.22451 9.67458C1.97446 9.42453 1.83398 9.08539 1.83398 8.73177V2.73177C1.83398 2.37815 1.97446 2.03901 2.22451 1.78896C2.47456 1.53891 2.8137 1.39844 3.16732 1.39844H9.16732C9.52094 1.39844 9.86008 1.53891 10.1101 1.78896C10.3602 2.03901 10.5007 2.37815 10.5007 2.73177V3.39844"
                        stroke="#219D50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4602_62347">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0.5 0.0664062)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>{prescription?.curr_pharmacy_name}</p>
                </div>
              </div>
              <div className="doctor-info">
                <span className="name">Pharmacy Phone</span>
                <div className="doctor-name-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_4602_62347)">
                      <path
                        d="M13.8333 6.06641H7.83333C7.09695 6.06641 6.5 6.66336 6.5 7.39974V13.3997C6.5 14.1361 7.09695 14.7331 7.83333 14.7331H13.8333C14.5697 14.7331 15.1667 14.1361 15.1667 13.3997V7.39974C15.1667 6.66336 14.5697 6.06641 13.8333 6.06641Z"
                        stroke="#219D50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.83398 10.0651H3.16732C2.8137 10.0651 2.47456 9.92463 2.22451 9.67458C1.97446 9.42453 1.83398 9.08539 1.83398 8.73177V2.73177C1.83398 2.37815 1.97446 2.03901 2.22451 1.78896C2.47456 1.53891 2.8137 1.39844 3.16732 1.39844H9.16732C9.52094 1.39844 9.86008 1.53891 10.1101 1.78896C10.3602 2.03901 10.5007 2.37815 10.5007 2.73177V3.39844"
                        stroke="#219D50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4602_62347">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0.5 0.0664062)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>{prescription?.curr_pharmacy_phone}</p>
                </div>
              </div>
              {!prescription?.isTransferEntireFile ? (
                <>
                  <div className="doctor-info">
                    <span className="name">Medication Names</span>
                    <div className="doctor-name-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4602_62347)">
                          <path
                            d="M13.8333 6.06641H7.83333C7.09695 6.06641 6.5 6.66336 6.5 7.39974V13.3997C6.5 14.1361 7.09695 14.7331 7.83333 14.7331H13.8333C14.5697 14.7331 15.1667 14.1361 15.1667 13.3997V7.39974C15.1667 6.66336 14.5697 6.06641 13.8333 6.06641Z"
                            stroke="#219D50"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.83398 10.0651H3.16732C2.8137 10.0651 2.47456 9.92463 2.22451 9.67458C1.97446 9.42453 1.83398 9.08539 1.83398 8.73177V2.73177C1.83398 2.37815 1.97446 2.03901 2.22451 1.78896C2.47456 1.53891 2.8137 1.39844 3.16732 1.39844H9.16732C9.52094 1.39844 9.86008 1.53891 10.1101 1.78896C10.3602 2.03901 10.5007 2.37815 10.5007 2.73177V3.39844"
                            stroke="#219D50"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4602_62347">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                              transform="translate(0.5 0.0664062)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p>{prescription?.medication_names}</p>
                    </div>
                  </div>
                  <div className="doctor-info">
                    <span className="name">Prescription Numbers</span>
                    <div className="doctor-name-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4602_62347)">
                          <path
                            d="M13.8333 6.06641H7.83333C7.09695 6.06641 6.5 6.66336 6.5 7.39974V13.3997C6.5 14.1361 7.09695 14.7331 7.83333 14.7331H13.8333C14.5697 14.7331 15.1667 14.1361 15.1667 13.3997V7.39974C15.1667 6.66336 14.5697 6.06641 13.8333 6.06641Z"
                            stroke="#219D50"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.83398 10.0651H3.16732C2.8137 10.0651 2.47456 9.92463 2.22451 9.67458C1.97446 9.42453 1.83398 9.08539 1.83398 8.73177V2.73177C1.83398 2.37815 1.97446 2.03901 2.22451 1.78896C2.47456 1.53891 2.8137 1.39844 3.16732 1.39844H9.16732C9.52094 1.39844 9.86008 1.53891 10.1101 1.78896C10.3602 2.03901 10.5007 2.37815 10.5007 2.73177V3.39844"
                            stroke="#219D50"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4602_62347">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                              transform="translate(0.5 0.0664062)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p>{prescription?.prescription_numbers}</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="doctor-info">
                  <span className="name">Transfer Entire File</span>
                  <div className="doctor-name-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4602_62347)">
                        <path
                          d="M13.8333 6.06641H7.83333C7.09695 6.06641 6.5 6.66336 6.5 7.39974V13.3997C6.5 14.1361 7.09695 14.7331 7.83333 14.7331H13.8333C14.5697 14.7331 15.1667 14.1361 15.1667 13.3997V7.39974C15.1667 6.66336 14.5697 6.06641 13.8333 6.06641Z"
                          stroke="#219D50"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.83398 10.0651H3.16732C2.8137 10.0651 2.47456 9.92463 2.22451 9.67458C1.97446 9.42453 1.83398 9.08539 1.83398 8.73177V2.73177C1.83398 2.37815 1.97446 2.03901 2.22451 1.78896C2.47456 1.53891 2.8137 1.39844 3.16732 1.39844H9.16732C9.52094 1.39844 9.86008 1.53891 10.1101 1.78896C10.3602 2.03901 10.5007 2.37815 10.5007 2.73177V3.39844"
                          stroke="#219D50"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4602_62347">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.5 0.0664062)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <p>yes</p>
                  </div>
                </div>
              )}
            </>
          ) : prescription?.attachments &&
            prescription?.attachments?.length > 0 ? (
            prescription?.attachments?.map((attachment, index) => (
              <img
                key={index}
                src={attachment.toString()}
                alt="prescription image"
              />
            ))
          ) : (
            <div className="doctor-info">
              <span className="name">Prescription Number(s)</span>
              <div className="doctor-name-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_4602_62347)">
                    <path
                      d="M13.8333 6.06641H7.83333C7.09695 6.06641 6.5 6.66336 6.5 7.39974V13.3997C6.5 14.1361 7.09695 14.7331 7.83333 14.7331H13.8333C14.5697 14.7331 15.1667 14.1361 15.1667 13.3997V7.39974C15.1667 6.66336 14.5697 6.06641 13.8333 6.06641Z"
                      stroke="#219D50"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.83398 10.0651H3.16732C2.8137 10.0651 2.47456 9.92463 2.22451 9.67458C1.97446 9.42453 1.83398 9.08539 1.83398 8.73177V2.73177C1.83398 2.37815 1.97446 2.03901 2.22451 1.78896C2.47456 1.53891 2.8137 1.39844 3.16732 1.39844H9.16732C9.52094 1.39844 9.86008 1.53891 10.1101 1.78896C10.3602 2.03901 10.5007 2.37815 10.5007 2.73177V3.39844"
                      stroke="#219D50"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4602_62347">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5 0.0664062)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p>{prescription.prescription_numbers}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="right-side-message">
        <div className="green-message">
          <h1>Notes</h1>
          {chat?.messages?.length > 0 ? (
            <div ref={scrollableChatRef} className="scrollable-chat">
              {chat?.messages?.map((message, index) => (
                <div
                  key={index}
                  className={`person-chat ${
                    index === chat?.messages?.length - 1 && 'new-message'
                  }`}
                >
                  <div className="top-part">
                    {isMe(message) && (
                      <Avatar alt="Doctor" src={user?.profile_img} />
                    )}
                    {!isMe(message) && (
                      <Avatar
                        alt={prescription?.customerDetails?.firstname}
                        src={prescription?.customerDetails?.profile_img}
                      />
                    )}
                    <h1>
                      {isMe(message)
                        ? 'Me'
                        : prescription?.customerDetails?.firstname}
                    </h1>
                  </div>
                  <h1>{formatDate(message?.createdAt)}</h1>
                  <p>{message?.content}</p>
                  <h1 style={{ borderTop: '1px solid #A3D0B5' }}></h1>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty-message">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="66"
                height="65"
                viewBox="0 0 66 65"
                fill="none"
              >
                {/* SVG Paths */}
              </svg>
              <p>No message found.</p>
            </div>
          )}
        </div>
        <div className="typing-box">
          <InputBase
            className="mui-register-password"
            type="text"
            placeholder="Type message"
            onChange={handleChangeMessage}
            value={message}
            onKeyUp={(event) => {
              if (event.key === 'Enter') submitMessage();
            }}
            endAdornment={
              <IconButton edge="end" onClick={submitMessage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <g clipPath="url(#clip0_3367_95933)">
                    <path
                      d="M14.6673 1.40039L7.33398 8.73372"
                      stroke="#219D50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.6673 1.40039L10.0007 14.7337L7.33398 8.73372L1.33398 6.06706L14.6673 1.40039Z"
                      stroke="#219D50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3367_95933">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.0664062)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </IconButton>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionImagesAndNotes;

import React from 'react';
import CityCheckbox from './CityCheckbox';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/callBack';

const AllPrescriptionTable = ({
  data,
  selectAllPrescriptions,
  checkedPrescriptions,
  handleCheckPrescriptions,
}) => {
  const navigate = useNavigate();
  return (
    <table>
      <thead>
        <tr>
          <th>
            <div className="inside-cell">
              <CityCheckbox
                onClick={selectAllPrescriptions}
                checked={
                  checkedPrescriptions?.length === data?.length &&
                  checkedPrescriptions?.length
                }
              />
              <p>Prescription number</p>
            </div>
          </th>
          <th>Type</th>
          <th>Refill Date</th>
          <th>Status</th>
          <th>Patient</th>
          <th>Phone Number</th>
          <th>Action</th>
        </tr>
      </thead>
      {data.map((prescription, index) => {
        const isChecked = checkedPrescriptions.some(
          (checkedPresc) => checkedPresc._id === prescription._id
        );
        return (
          <tbody key={prescription._id ?? index}>
            <tr className={isChecked && 'checked'}>
              <td>
                <div className="inside-cell">
                  <CityCheckbox
                    checked={isChecked}
                    onClick={(event) =>
                      handleCheckPrescriptions(event, prescription)
                    }
                  />
                  <p>
                    {prescription?.number ? '#' + prescription?.number : 'NA'}
                  </p>
                  {prescription.new && (
                    <div className="green-box">
                      <p className="green">New</p>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <div className="grey-box">
                  <p>{prescription?.type ?? 'NA'}</p>
                </div>
              </td>
              <td>{formatDate(prescription?.refill_date) ?? 'NA'}</td>
              <td>
                <div
                  className={
                    prescription?.status === 'Processing'
                      ? 'grey-box2'
                      : prescription?.status === 'Paid' ||
                        prescription.status === 'Delivered'
                      ? 'green-box2'
                      : prescription.status === 'Cancelled'
                      ? 'red-box'
                      : 'light-green-box'
                  }
                >
                  <p>{prescription?.status ?? 'NA'}</p>
                </div>
              </td>
              <td>
                {prescription?.patient_fname || prescription?.patient_lname
                  ? prescription?.patient_fname +
                    ' ' +
                    prescription?.patient_lname
                  : 'NA'}
              </td>
              <td>{prescription.phone ?? 'NA'}</td>
              <td>
                <div className="inside-cell">
                  <button
                    onClick={() =>
                      navigate(`/prescription/${prescription._id}`)
                    }
                    className="button-review"
                  >
                    Review
                  </button>
                  {/* <IconButton>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </IconButton> */}
                </div>
              </td>
            </tr>
          </tbody>
        );
      })}
    </table>
  );
};

export default AllPrescriptionTable;
